import { useContext } from "react"
import { LanguageContext } from "./languageContext"
import { dictionaryGlobal } from "apis/dictionary/global"

export default function useMultilang() {
  const { translations, language, setSelectedLanguage } =
    useContext(LanguageContext)

  const getT = (key) => {
    if (translations[key] == null) {
      dictionaryGlobal.addTranslation(key).then(res => {
          if(res?.success) {
            // console.log(res)
          } else {
            // console.log(res)
          }
        })
    }
    return translations[key] && translations[key] !== ""
      ? translations[key]
      : key
  }

  return {
    language,
    translations,
    getT,
    setSelectedLanguage,
  }
}
