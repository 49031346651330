import { useState, createContext, useRef, useEffect } from 'react'

const ModalContext = createContext()

const ModalProvider = (props) => {

    const openModalsRef = useRef([])
    const [openModals, setOpenModals] = useState([])

    useEffect(() => {
        openModalsRef.current = openModals
      }, [openModals])
    
    const popModal = () => {
        openModalsRef.current.pop()
        setOpenModals([...openModalsRef.current])
    }
    
    const addModal = (modal) => {
        openModalsRef.current.push(modal)
        setOpenModals([...openModalsRef.current])
    }

    const closeAllModal = () => setOpenModals([])

    return (
        <ModalContext.Provider
            value={{addModal, popModal, closeAllModal, openModals}}
        >
            {openModals.length > 0 && openModals.map(modal => modal)}
            {props.children}
        </ModalContext.Provider>
    )
}

export { ModalContext, ModalProvider }