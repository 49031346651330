import "swiper/swiper.scss"
import "style.scss"
import "react-toastify/dist/ReactToastify.css"
import { LoginProvider } from "contexts/loginContext"
import { CommProvider } from "contexts/commContext"
import { GlobalProvider } from "contexts/globalContext"
import { InputHandlerProvider } from "contexts/InputHandlerProvider"
import { LanguageProvider } from "intl/languageContext"
import { SettingsProvider } from "intl/settingsContext"
import { HelpProvider } from "help/helpContext"
import Main from "Main"

function App() {
  return (
    <LanguageProvider app="ipanel" group="main">
      <HelpProvider>
        <LoginProvider>
          <InputHandlerProvider>
            <CommProvider>
              <GlobalProvider>
                <SettingsProvider>
                  <Main />
                </SettingsProvider>
              </GlobalProvider>
            </CommProvider>
          </InputHandlerProvider>
        </LoginProvider>
      </HelpProvider>
    </LanguageProvider>
  )
}

export default App
