const dicitionary = {
    "firstName": { "hu": "Keresztnév", "en": "First name" },
    "lastName": { "hu": "Vezetéknév", "en": "Last name" },
    "email": { "hu": "Email cím", "en": "Email address" },
    "telNumber": { "hu": "Telefonszám", "en": "Phone" },
    "address": { "hu": "Cím", "en": "Address" },
    "zip": { "hu": "Irányítószám", "en": "Zip" },
    "city": { "hu": "Város", "en": "City" },
    "floor": { "hu": "Emelet", "en": "Floor" },
    "state": { "hu": "Megye", "en": "State" },
    "street": { "hu": "Utca", "en": "Street" },
    "streetNumber": { "hu": "Házszám", "en": "Street number" },
    "description": { "hu": "Leírás", "en": "Description" },
    "countryCode": { "hu": "Ország kód", "en": "Country code" },
    "companyID": { "hu": "Cég azonosító", "en": "Company id" },
    "companyName": { "hu": "Cégnév", "en": "Company name" },
    "taxNumber": { "hu": "Adószám", "en": "Tax number" },
    "name": { "hu": "Név", "en": "Name" },
    "shortName": { "hu": "Rövid név", "en": "Short name" },
    "lat": { "hu": "Szélesség", "en": "Latitude" },
    "lng": { "hu": "Hosszúság", "en": "Longitude" },
    "next": { "hu": "Tovább", "en": "Next" },
    "prev": { "hu": "Vissza", "en": "Previous" },
    "send": { "hu": "Elküld", "en": "Send" },
    "back": { "hu": "Vissza", "en": "Back" },
    "personal": { "hu": "Személyes adatok", "en": "Account owner"},
    "company": { "hu": "Cég adatok", "en": "Company"},
    "location": { "hu": "Üzlet adatai", "en": "Location"},
    "sum": { "hu": "Összegzés", "en": "Summary"},
    "done": { "hu": "Sikeres elküldés", "en": "Done"},
    "success": { "hu": "Sikeres beküldés", "en": "Done"},
    "checkMailbox": { "hu": "Ellenőrizd az e-mail címed a véglegesítéshez", "en": "Check your mailbox"}
}

export default dicitionary