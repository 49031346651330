import Routes from 'components/AppRoutes'
import LoginScreen from 'views/LoginScreen'
import MainPanel from 'views/MainPanel'
import LoadingScreen from 'components/LoadingScreen'
import pack from '../package.json'
import { isMobileOnly } from "react-device-detect"
import { ModalProvider } from 'contexts/modalContext'
import { useState, useEffect, useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import { GlobalContext } from 'contexts/globalContext'
import { useLocation } from 'react-router-dom'

export default function Main () {
    
  const [loading, setLoading] = useState(true)
  const {darkMode} = useContext(GlobalContext)
  const pathname = useLocation().pathname
  const module = pathname.split("/")[1]

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 300)
    return () => { clearTimeout(timer) }
  }, [])

    return <main className={`${isMobileOnly ? "main-mobile-container" : "main-container"} ${darkMode === "1" ? "dark-mode" : "light-mode"} ${module}`}>
    <ModalProvider>
      <ToastContainer 
        position="top-center"
        autoClose={300}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        toastStyle={darkMode === "1" ? { backgroundColor: "#1f2023", color: "#eceff1" } : {} }
      />
      <LoadingScreen loading={loading} />
      <LoginScreen />
      <MainPanel>
        <Routes />
      </MainPanel>
    </ModalProvider>
    <div className="version-container">v{pack.version}</div>
  </main>
}