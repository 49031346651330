import axios from 'axios'
import {config} from 'config.js'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage['iPanel-apiToken']
  }
})

export const dictionaryGlobal = {
  getTranslations: async (app, group, language) => {
    try {
      const axiosconfig = {
        params: {
          app, 
          group, 
          language
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/dictionary/global`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getAllTranslations: async (filter) => {
    try {
      const axiosconfig = {
        params: { 
          filter
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/dictionary/global/all`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getTranslation: async (id) => {
    try {
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/dictionary/global/${id}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  setTranslation: async (data) => {
    let body = {
      data
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/dictionary/global`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  addTranslation: async (key) => {
    let body = {
      key
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/dictionary/global/new`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
}
