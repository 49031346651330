import { createContext, useEffect, useState, useRef, useContext } from "react"

const InputHandlerContext = createContext()

function InputHandlerProvider(props) {
  const previousKeyDown = useRef({ timeStamp: 0 })
  const machineText = useRef("")
  const counterRef = useRef(null)
  const isMachineRef = useRef(false)
  const eventListenerAdded = useRef(false)
  const [isMachine, setIsMachine] = useState(false)
  const [machineValue, setMachineValue] = useState(null)

  const languageKeySwapper = {
    EN: {},
    HU: {
      ö: "0",
      z: "y",
      Z: "Y",
      y: "z",
      Y: "Z",
      É: ":",
      "-": "/",
    },
  }

  const keyCorrector = (key) => {
    let response = languageKeySwapper?.[localStorage["reader-lang"] || "HU"]?.[key]
    return response != null ? response : key
  }

  const keydown = (e) => {
    if( e?.key !== "Enter" && e?.key?.length > 1) {
      return null
    }
    if (isMachineRef.current) {
      e.preventDefault()
    }
    if (e.key === "Enter") return null
    if (e.timeStamp - previousKeyDown.current.timeStamp < 40) {
      machineText.current = machineText.current + "" + keyCorrector(e.key)
      isMachineRef.current = true
      countDown()
    } else {
      machineText.current = keyCorrector(e.key)
    }
    previousKeyDown.current = e
  }

  const countDown = () => {
    clearTimeout(counterRef.current)
    counterRef.current = setTimeout(() => {
      if (machineText.current.length > 8) {
        timeOverMachine()
      } else {
        isMachineRef.current = false
      }
    }, 40)
  }

  const timeOverMachine = () => {
    setMachineValue(machineText.current)
    setIsMachine(true)
    setTimeout(() => {
      setMachineValue(null)
      setIsMachine(false)
      isMachineRef.current = false
    }, 50)
  }

  useEffect(() => {
    if (!eventListenerAdded.current) {
      eventListenerAdded.current = true
      window.addEventListener("keydown", (e) => {
        keydown(e)
      })
    }
    // eslint-disable-next-line
  }, [])

  return <InputHandlerContext.Provider value={{ machineValue, isMachine, isMachineRef }}>{props.children}</InputHandlerContext.Provider>
}

export default function useInputHandler() {
  const { machineValue, isMachine, isMachineRef } = useContext(InputHandlerContext)
  return { machineValue, isMachine, isMachineRef }
}

export { InputHandlerProvider, InputHandlerContext }
