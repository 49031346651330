import axios from 'axios'

const axiosInstanceCommon = axios.create({
  baseURL: "https://ipanel.barsoft.hu",
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const commonApi = {

  getTaxData: async function (vatNumber) {
    try {
      let res = await axiosInstanceCommon.request({
        method: "GET",
        url: `/api/v1/common/tax/${vatNumber}`,
      })
      return res.data
    } catch(e) {
      return { success: false, data: {} }
    }
  },
  getRegionByZip: async function (zip) {
    try {
      let res = await axiosInstanceCommon.request({
        method: "GET",
        url: `/api/v1/common/address/region/zip/${zip}`
      })
      return res.data
    } catch(e) {
      return { success: false, data: {} }
    }
  }
}