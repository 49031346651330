const rules = {
  global: {
    dashboard: { roles: ["globalAdmin"] },
    listUsers: { roles: ["globalAdmin"] },
    listDictionary: { roles: ["globalAdmin"] },
    listBrands: { roles: ["globalAdmin"] },
    devices: { roles: ["globalAdmin"] },
    templates: { roles: ["globalAdmin"] },
  },
  brands: {
    dashboard: {
      roles: [
        "brandAdmin",
        "brandManager",
        "brandShiftManager",
        "brandUser",
        "brandReseller",
        "brandEditor",
      ],
    },
    listOpenHours: {
      roles: ["brandAdmin", "brandManager", "brandShiftManager"],
    },
    listLocations: {
      roles: ["globalAdmin", "brandReseller", "brandOwner", "brandAdmin"],
    },
    listLocationsEnabled: { roles: ["globalAdmin"] },
    listLocationsIntegration: { roles: ["globalAdmin"] },
    listUsers: { roles: ["brandReseller"] },
    listTables: { roles: ["brandAdmin", "brandManager"] },
    listOrders: {
      roles: [
        "brandAdmin",
        "brandManager",
        "brandShiftManager",
        "brandReseller",
      ],
    },
    listTemplates: { roles: ["globalAdmin"] },
    listDictionary: { roles: ["globalAdmin"] },
    listCatalog: {
      roles: [
        "brandAdmin",
        "brandManager",
        "brandShiftManager",
        "brandReseller",
      ],
    },
    listDevices: { roles: ["globalAdmin", "brandAdmin", "brandReseller"] },
    listPrinters: { roles: ["globalAdmin", "brandAdmin", "brandReseller"] },
    listPayments: { roles: ["globalAdmin", "brandReseller", "brandAdmin"] },
    listPaymentAgents: {
      roles: ["globalAdmin", "brandReseller", "brandAdmin"],
    },
    listOnlineProfiles: { roles: ["globalAdmin", "brandReseller"] },
    listInvoiceProfiles: { roles: ["globalAdmin", "brandReseller"] },
    listItems: { roles: ["brandAdmin", "brandReseller"] },
    listItemAvailability: {
      roles: [
        "brandAdmin",
        "brandManager",
        "brandShiftManager",
        "brandReseller",
      ],
    },
    listNews: { roles: ["globalAdmin"] },
    rebuildCache: { roles: ["brandAdmin"] },
    createTable: { roles: ["brandAdmin", "brandManager"] },
    reports: { roles: ["brandAdmin"] },
    listSanitycheck: { roles: ["brandAdmin", "globalAdmin"] },
    listNtak: { roles: ["globalAdmin"] },
    listErrorLogs: { roles: ["brandManager", "brandReseller"] },
    listContents: { roles: ["globalAdmin"] },
    listDiscounts: { roles: ["brandAdmin", "globalAdmin"] },
    appEditor: { roles: ["brandAdmin", "globalAdmin", "brandEditor"] },
  },
  vip: {
    listCardTypes: { roles: ["globalAdmin", "vipAdmin"] },
    listCoupons: { roles: ["globalAdmin", "vipAdmin"] },
    listCustomers: { roles: ["globalAdmin", "vipAdmin"] },
    listItems: { roles: ["globalAdmin", "vipAdmin"] },
    listPurchases: { roles: ["globalAdmin", "vipAdmin"] },
    listStores: { roles: ["globalAdmin", "vipAdmin"] },
    listTemplates: { roles: ["globalAdmin", "vipAdmin"] },
    listTransactions: { roles: ["globalAdmin", "vipAdmin"] },
    readVipBrand: { roles: ["globalAdmin", "vipAdmin"] },
    readAllVipBrands: { roles: ["globalAdmin", "vipAdmin"] },
    createCardTypes: { roles: ["globalAdmin", "vipAdmin"] },
    createCoupons: { roles: ["globalAdmin", "vipAdmin"] },
    createStores: { roles: ["globalAdmin", "vipAdmin"] },
    createCustomers: { roles: ["globalAdmin", "vipAdmin"] },
    updateItems: { roles: ["globalAdmin", "vipAdmin"] },
    updateCustomerPoints: { roles: ["globalAdmin", "vipAdmin"] },
    uploadAsset: { roles: ["globalAdmin", "vipAdmin"] },
    sendNewsletter: { roles: ["globalAdmin", "vipAdmin"] },
  },
  reseller: {
    listBrands: { roles: [] },
    listCommissions: { roles: [] },
    listInvitation: { roles: [] },
    listBrandInits: { roles: [] },
    billing: { roles: [] },
  },
}

function checkPermission(rule, roles) {
  try {
    if (rule.roles?.length === 0) return true
    return rule.roles.some((role) => roles.includes(role))
  } catch (e) {
    return false
  }
}

function canUseFeature(
  user,
  app,
  feature,
  appID = null,
  appSubID = null,
  anySubID = false
) {
  if (user == null) return false
  if (user.roles.includes("globalAdmin")) return true
  if (
    user?.resellerCode != null &&
    user?.resellerCode !== "" &&
    (feature === "listLocations" ||
      feature === "listTables" ||
      feature === "listOpenHours" ||
      feature === "listDictionary" ||
      feature === "listDiscounts" ||
      feature === "listOnlineProfiles" ||
      feature === "listInvoiceProfiles")
  )
    return true
  let rule
  try {
    rule = rules[app][feature]
    if (rule == null) return false
  } catch (e) {
    return false
  }
  try {
    if (checkPermission(rule, user.roles)) {
      return true
    }
  } catch (e) {}
  try {
    if (appID == null) return false
    let appRoles = user.apps[app][appID].roles
    if (checkPermission(rule, appRoles)) return true

    if (user.apps[app][appID].subApps != null) {
      // Check also appSubID persmissions
      if (appSubID == null) {
        if (anySubID) {
          for (let roles of Object.values(user.apps[app][appID].subApps)) {
            //console.log(rule, roles, checkPermission(rule, roles))
            if (checkPermission(rule, roles)) return true
          }
        } else return false
      } else {
        let appSubRoles = user.apps[app][appID].subApps[appSubID]
        if (checkPermission(rule, appSubRoles)) return true
      }
    }
  } catch (e) {
    //okelog.log(e)
    return false
  }
  return false
}

export { canUseFeature }
