function Downloads() {

    return(
        <div className="downloads-root">
            <div className="card">
                <div className="img-container">
                    <img src="/assets/oktopos.png" alt="okto" />
                </div>
                <h3>Kiosk frame</h3>
                <p>latest version</p>
                <a className="btn" href="https://download.okeoke.io/latest.exe">Download</a>
            </div>
            <div className="card">
                <div className="img-container">
                    <img src="/assets/worker.png" alt="worker" />
                </div>
                <h3>Worker</h3>
                <p>latest version</p>
                <a className="btn" href="https://download.okeoke.io/worker.zip">Download</a>
            </div>
            <div className="card">
                <div className="img-container">
                    <img src="/assets/play.png" alt="worker" />
                </div>
                <h3>APK</h3>
                <p>latest version</p>
                <a className="btn" href="https://download.okeoke.io/latest.apk">Download</a>
            </div>
        </div>
    )
}
export default Downloads