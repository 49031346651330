import axios from 'axios'
import {config} from 'config.js'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage['iPanel-apiToken']
  }
})

export const brandOrders = {
  getOrders: async (brandID, filter) => {
    try {
      const axiosconfig = {
        params: {
          brandID,
          filter
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/orders`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getOrder: async (brandID, orderUUID) => {
    try {
      const axiosconfig = {
        params: {
          brandID
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/orders/${orderUUID}`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getExternalOrder: async (orderUUID) => {
    try {
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/orders/external/${orderUUID}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
}
