import axios from "axios"
import { config } from "config.js"

var axiosGlobal = axios.create({
  timeout: 5000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage["iPanel-apiToken"],
  },
})

export const sso = {
  createToken: async (appID) => {
    try {
      const response = await axiosGlobal.post(
        `${config().apiBaseUrl}api/v1/sso`,
        {
          appName: "brands",
          appID,
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
      return null
    }
  },
}
