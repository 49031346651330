import React from "react"
import ButtonSimple from "./ButtonSimple"
import useMultilang from 'intl/useMultilang'

function ButtonCancel(props) {

  const { getT } = useMultilang()

  return (
    <ButtonSimple 
      svgName="Cancel"
      className={"btn-red"}
      text={getT('components.button.cancel')}
      {...props}  
    />
  )
}

export default ButtonCancel
