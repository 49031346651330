import { useState, useEffect } from "react"
import dictionary from "../dictionary"
import GeoLocationPicker from "./GeoLocationPicker"
import Address from "./Address"

function Location(props) {
  const { formData, handleFormDataChange, language, getZipData } = props

  const [localData, setLocalData] = useState(formData)

  useEffect(() => {
    setLocalData(formData)
  }, [formData])

  const handleChange = (e) => {
    setLocalData({ ...localData, [e.target.name]: e.target.value })
  }

  const handleBlur = (e) => {
    handleFormDataChange("location", {
      ...localData,
      [e.target.name]: e.target.value,
    })
  }

  const handleGeoLocationChange = (e) => {
    console.log(e)
  }

  const handleAddressChange = (address) => {
    handleFormDataChange("location", { ...localData, address })
  }

  return (
    <div className="stage-container location">
      {Object.keys(formData || {}).map((data) => {
        if (data === "geolocation")
          return (
            <GeoLocationPicker
              key={data}
              data={localData?.[data]}
              onChange={handleGeoLocationChange}
            />
          )
        if (data === "address")
          return (
            <Address
              key={data}
              data={localData?.[data]}
              onChange={handleAddressChange}
              getZipData={getZipData}
              language={language}
            />
          )
        return (
          <div key={data}>
            <div className="input-label">{dictionary?.[data]?.[language]}</div>
            <input
              name={data}
              value={localData?.[data]}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        )
      })}
    </div>
  )
}
export default Location
