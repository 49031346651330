import { useState, useEffect } from "react"
import dictionary from "../dictionary"

function Address(props) {
  const { data, onChange, getZipData, language } = props

  const [localData, setLocalData] = useState(data)

  useEffect(() => {
    setLocalData(data)
  }, [data])

  const handleChange = (e) => {
    setLocalData({ ...localData, [e.target.name]: e.target.value })
  }

  const handleBlur = (e) => {
    if (e.target.name === "zip") {
      getZipData(e.target.value).then((res) => {
        if (res?.success) {
          onChange({
            ...localData,
            city: res.data?.region,
            state: res.data?.state,
          })
        } else {
          console.log(res)
        }
      })
    } else {
      onChange({ ...localData, [e.target.name]: e.target.value })
    }
  }

  return (
    <>
      {Object.keys(localData || {}).map((data) => (
        <div key={data}>
          <div className="input-label">{dictionary?.[data]?.[language]}</div>
          <input
            key={data}
            name={data}
            value={localData?.[data]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      ))}
    </>
  )
}
export default Address
