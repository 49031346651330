import { useContext, useEffect, useState } from 'react'
import { catalogControl } from 'apis/brand/catalog/control'
import ButtonSimple from 'components/Buttons/ButtonSimple'
import ButtonCancel from 'components/Buttons/ButtonCancel'
import { GlobalContext } from "contexts/globalContext"
import { toast } from 'react-toastify'
import { Progress } from 'semantic-ui-react'
import useMultilang from 'intl/useMultilang'

function RebuildModal(props) {
    
    const { popModal } = props
    const { brand } = useContext(GlobalContext)
    const { getT } = useMultilang()

    const [data, setData] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            getData()
        }, 500)
        return () => { clearInterval(interval) }
        // eslint-disable-next-line
    }, [])

    const getData = () => {
        if(brand?.id != null) {
            catalogControl.getRebuild(brand.id).then(res => {
                if(res?.success) {
                    setData(res.data)
                }
            })
        }
    }

    const handleStart = () => {
        if(brand?.id != null) {
            catalogControl.setRebuild(brand.id)
        } else {
            toast.error("No brand selected!", { autoClose: 2000, hideProgressBar: false })
        }
    }

    return(
        <div className="rebuild-modal-content">
            <div className="modal-header">
                <div className="modal-title">{getT("components.rebuild.title")}</div>
                <div className="modal-actions">
                    <ButtonSimple 
                        text={getT("components.rebuild.start")}
                        className="btn-green"
                        svgName="circleCheck"
                        onClick={handleStart}
                        disabled={data?.building}
                    />
                    <ButtonCancel
                        text="Close"
                        onClick={popModal}
                    />
                </div>
            </div>
            <div className="progress" style={{height: "32px"}}>
                {data?.building && <div className="hidden appear"><Progress percent={data?.items?.buildPercent} indicating /></div>}
                {!data?.building && getT("components.rebuild.pressButtonText")}
            </div>
            
        </div>
    )
}
export default RebuildModal