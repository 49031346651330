import { useState, useEffect, createContext, useMemo, useContext } from "react"
import { LoginContext } from "./loginContext"
import { vipBrandsApi } from "../apis/vip/brands"
import { brandsApi } from "../apis/brand/brands"

const GlobalContext = createContext()

function reduceEntitiesVipBrands(rows) {
  let final = {}
  if (rows == null) return final
  for (let row of rows) {
    final[`${row.id}`] = row
  }
  return final
}

async function reduceEntitiesBrands(rows) {
  let final = {}
  for await (let row of rows || []) {
    row.brandID = row.id
    final[`${row.id}`] = row
  }
  return final
}

const GlobalProvider = (props) => {
  const { loggedIn } = useContext(LoginContext)
  const [brands, setBrands] = useState(null)
  const [brand, setBrand] = useState(localStorage["ipanel-brand"])
  const [vipBrands, setVipBrands] = useState(null)
  const [vipBrand, setVipBrand] = useState(localStorage["ipanel-vip-brand"])
  const [darkMode, setDarkMode] = useState(localStorage["darkMode"])
  const value = useMemo(() => ({ brands, setBrands }), [brands])
  const value2 = useMemo(() => ({ brand, setBrand }), [brand])
  const value3 = useMemo(() => ({ vipBrands, setVipBrands }), [vipBrands])
  const value4 = useMemo(() => ({ vipBrand, setVipBrand }), [vipBrand])
  const value5 = useMemo(() => ({ darkMode, setDarkMode }), [darkMode])

  useEffect(() => {
    if (loggedIn) {
      vipBrandsApi.getVipBrands().then((res) => {
        let selectedVipBrand =
          localStorage["ipanel-vip-brand"] != null && localStorage["ipanel-vip-brand"] !== "null" ? localStorage["ipanel-vip-brand"] : res.data[0]?.id
        setVipBrand(selectedVipBrand)
        setVipBrands(reduceEntitiesVipBrands(res.data))
      })
      brandsApi.getBrands().then((res) => {
        reduceEntitiesBrands(res.data).then((data) => {
          let selectedBrand = null
          try {
            let ipanelBrand = localStorage["ipanel-brand"]
            if ([undefined, null, "undefined", "null"].includes(ipanelBrand)) {
              selectedBrand = Object.values(data)[0]
            } else {
              selectedBrand = JSON.parse(ipanelBrand)
            }
          } catch (e) {
            console.log(e)
          }
          setBrand(selectedBrand)
          setBrands(data)
        })
      })
    } else {
      setBrands(null)
      setVipBrands(null)
    }
  }, [loggedIn])

  useEffect(() => {
    localStorage["ipanel-brand"] = typeof brand === "object" ? JSON.stringify(brand) : brand
  }, [brand])

  useEffect(() => {
    localStorage["ipanel-vip-brand"] = vipBrand
  }, [vipBrand])

  return <GlobalContext.Provider value={{ ...value, ...value2, ...value3, ...value4, ...value5 }}>{props.children}</GlobalContext.Provider>
}

export { GlobalContext, GlobalProvider }
