import React, { useEffect, useState, useContext, useRef} from 'react'
import { LoginContext } from 'contexts/loginContext'

function LoadingScreen(props) {
    
    const { loading } = props
    const { loggedIn } = useContext(LoginContext)
    const timer = useRef(null)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(loading) 
    }, [loading])

    useEffect(() => {
        clearTimeout(timer.current)
        setVisible(true)
        timer.current = setTimeout(() => {
            setVisible(false)
        }, 300)
    }, [loggedIn])

    return(
        <div className={`loading-screen ${visible ? "visible" : "fade-out"}`}>
            {/*<GridLoader color={"white"} loading={loading} />*/}
        </div>  
    )
}

export default LoadingScreen