import axios from 'axios'
import {config} from 'config.js'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage['iPanel-apiToken']
  }
})

export const brandInit = {
  sendData: async (data) => {
    try {
      const body = {
        ...data
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/brand/init/submit`, body)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getInvites: async (filter) => {
    try {
      const axiosconfig = {
        params: {
          filter
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/init/invite`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getInvite: async (id, resellerCode) => {
    try {
      const axiosconfig = {
        params: {
          resellerCode,
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/init/invite/${id}`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getInviteValid: async (uuid, resellerCode) => {
    try {
      const axiosconfig = {
        params: {
          resellerCode,
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/init/invite/validate/${uuid}`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  sendInvite: async (invite) => {
    try {
      const body = {
        invite
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/brand/init/invite`, body)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getInits: async (filter) => {
    try {
      const axiosconfig = {
        params: {
          filter
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/init/`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getInit: async (uuid) => {
    try {
      const axiosconfig = {
        params: {
          
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/init/${uuid}`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  restartDeploy: async (deployID) => {
    try {
      const body = {
        deployID
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/brand/init/restartDeploy`, body)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
}