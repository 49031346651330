import { useState, useEffect, createContext, useContext } from 'react'
import { GlobalContext } from "contexts/globalContext"

const SettingsContext = createContext()

const SettingsProvider = (props) => {
  
  const { brand } = useContext(GlobalContext)

  const [settings, setSettings] = useState(null)

  useEffect(() => {
    if(localStorage['iPanel-userSettings'] != null) {
      try {
        let data = JSON.parse(localStorage['iPanel-userSettings'] || {})
        setSettings(data)
      } catch(e) {
        console.log(e)
      }
    }
  }, [])

  useEffect(() => {
    if(settings != null) {
      localStorage['iPanel-userSettings'] = JSON.stringify(settings)
    }
  }, [settings])

  const saveSettings = (key, value) => {
    
    if(brand?.id == null) return null

    if(settings?.[brand.id] == null) {
      setSettings({...settings, [brand.id]: {[key]: value}})
    } else {
      setSettings({...settings, [brand.id]: {...settings[brand.id], [key]: value}})
    }
  }

  return (
    <SettingsContext.Provider value={{ settings, saveSettings }}>
      {props.children}
    </SettingsContext.Provider>
  )
}

export { SettingsContext, SettingsProvider }