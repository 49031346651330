import { useState, useEffect } from "react"
import dictionary from "../dictionary"

function Company(props) {
  const { formData, handleFormDataChange, language, getTaxData } = props

  const [localData, setLocalData] = useState(formData)

  useEffect(() => {
    setLocalData(formData)
  }, [formData])

  const handleChange = (e) => {
    setLocalData({ ...localData, [e.target.name]: e.target.value })
  }

  const handleBlur = (e) => {
    getTaxData(e.target.value).then((res) => {
      if (res?.success) {
        handleFormDataChange("company", {
          taxNumber: e.target.value,
          companyID: localData.companyID,
          name: res.data.name,
          shortName: res.data.shortName,
          address: {
            zip: res.data.zip,
            city: res.data.city,
            floor: "",
            state: "",
            street: res.data.street,
            telNumber: "",
            countryCode: "HU",
            streetNumber: res.data.streetNumber,
          },
        })
      } else {
        console.log(res)
      }
    })
  }

  return (
    <div className="stage-container company">
      <div className="input-label">{dictionary?.["taxNumber"]?.[language]}</div>
      <input
        name={"taxNumber"}
        value={localData?.["taxNumber"]}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className="input-label">{dictionary?.["name"]?.[language]}</div>
      <input name={"name"} value={localData?.["name"]} />
      <div className="input-label">{dictionary?.["shortName"]?.[language]}</div>
      <input name={"shortName"} value={localData?.["shortName"]} />
      <div className="input-label">
        {dictionary?.["countryCode"]?.[language]}
      </div>
      <input name={"countryCode"} value={localData?.address?.["countryCode"]} />
      <div className="input-label">{dictionary?.["zip"]?.[language]}</div>
      <input name={"zip"} value={localData?.address?.["zip"]} />
      <div className="input-label">{dictionary?.["city"]?.[language]}</div>
      <input name={"city"} value={localData?.address?.["city"]} />
      <div className="input-label">{dictionary?.["street"]?.[language]}</div>
      <input name={"street"} value={localData?.address?.["street"]} />
      <div className="input-label">
        {dictionary?.["streetNumber"]?.[language]}
      </div>
      <input
        name={"streetNumber"}
        value={localData?.address?.["streetNumber"]}
      />
    </div>
  )
}
export default Company
