import React, { useContext } from "react"
import AccordionContext from "./AccordionContext"
import LoadSvg from "components/LoadSvg-v3"
import ClipLoader from "react-spinners/ClipLoader"

function AccordionHead(props) {

    const { onClick } = props

    const { isOpen, isLoading, changeOpenState, iconType } = useContext(AccordionContext)

    const handleClickHeader = () => {
        changeOpenState(!isOpen)
        if(typeof onClick === "function") onClick()
    }

    return(
        <div className={`accordion-header ${isOpen ? 'accordion-open' : 'accordion-closed'}`} onClick={handleClickHeader}>
            {props.children}
            {isLoading && <div className="accordion-loading hidden appear"><ClipLoader color={"grey"} size={20} /></div>}
            {!isLoading && <div className="accordion-expandIcon hidden appear">
                {iconType == null && <LoadSvg name="chevron" />}
                {iconType === "type2" && <LoadSvg name="roundedArrow" />}
            </div>}
        </div>
    )
}

export default AccordionHead