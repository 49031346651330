import { lazy, Suspense } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import OkeOkeLoading from "components/OkeOkeLoading"
import OrderView from "components/OrderView"
import CreateBrand from "views/External/CreateBrand"
import BrandStatus from "views/External/BrandStatus"
import CreateBrandSuccess from "views/External/CreateBrandSuccess"
import CreateBrandError from "views/External/CreateBrandError"
import Downloads from "views/External/Downloads"
import TeyaAuth from "views/External/TeyaAuth"
//import { isMobileOnly } from "react-device-detect"

// const MoBrandDashboard = lazy(() =>
//   import("views/_Mobile/Brand/Stat/Dashboard")
// )

// const MoBrandLocations = lazy(() =>
//   import("views/_Mobile/Brand/Locations/Dashboard")
// )
// const MoBrandDevices = lazy(() =>
//   import("views/_Mobile/Brand/Locations/Devices")
// )

// const MoBrandMenu = lazy(() => import("views/_Mobile/Brand/Catalog/MenuEditor"))
// const MoBrandItemAvailability = lazy(() =>
//   import("views/_Mobile/Brand/Catalog/ItemAvailability")
// )
// const MoBrandModifierAvailability = lazy(() =>
//   import("views/_Mobile/Brand/Catalog/ModifierAvailability")
// )
// const MoBrandModifiers = lazy(() =>
//   import("views/_Mobile/Brand/Catalog/Modifiers")
// )
// const MoBrandVatGroups = lazy(() =>
//   import("views/_Mobile/Brand/Catalog/VatGroups")
// )

// const MoVipDashboard = lazy(() => import("views/_Mobile/Vip/Dashboard"))

// const MoGlobalDashboard = lazy(() => import("views/_Mobile/Global/Dashboard"))
// const MoGlobalDictionary = lazy(() => import("views/_Mobile/Global/Dictionary"))

// const MoResellerDashboard = lazy(() =>
//   import("views/_Mobile/Reseller/Dashboard")
// )

const GlobalUsers = lazy(() => import("views/Global/Users"))
const Dictionary = lazy(() => import("views/Global/Dictionary"))
const GlobalBrandDictionary = lazy(() => import("views/Global/BrandDictionary"))
const Templates = lazy(() => import("views/Global/Templates"))
const GlobalBrands = lazy(() => import("views/Global/Brands"))
const Billing = lazy(() => import("views/Global/Billing"))
const GlobalBrandSettings = lazy(() => import("views/Global/GlobalBrandSettings"))
const CloudDevices = lazy(() => import("views/Global/CloudDevices"))
const BrandInits = lazy(() => import("views/Global/BrandInits"))
const Logs = lazy(() => import("views/Global/Logs"))
const Help = lazy(() => import("views/Global/Help"))
const Resellers = lazy(() => import("views/Global/Resellers"))
const AppEditorView = lazy(() => import("views/Global/AppEditorView"))

//const ResellerBrands = lazy(() => import("views/Reseller/Brands"))
const ResellerInvitations = lazy(() => import("views/Reseller/Invitations"))
const ResellerCommissions = lazy(() => import("views/Reseller/Commissions"))

const VipCoupons = lazy(() => import("views/Vip/Coupons"))
const VipCustomers = lazy(() => import("views/Vip/Customers"))
const VipPurchases = lazy(() => import("views/Vip/Purchases"))
const VipTransactions = lazy(() => import("views/Vip/Transactions"))
const VipCardTypes = lazy(() => import("views/Vip/CardTypes"))
const CustomerPanel = lazy(() => import("views/Vip/CustomerPanel"))
const VipDashboard = lazy(() => import("views/Vip/Dashboard"))
const VipTemplates = lazy(() => import("views/Vip/Templates"))
const LocationGroups = lazy(() => import("views/Vip/LocationGroups"))
const SingleGroup = lazy(() => import("views/Vip/LocationGroups/SingleGroup"))
const VipCategories = lazy(() => import("views/Vip/VipCategories"))
const VipItemEditor = lazy(() => import("views/Vip/VipItemEditor"))
const VipIntegrationSchemas = lazy(() => import("views/Vip/IntegrationSchemas"))
const VipStores = lazy(() => import("views/Vip/Stores"))

const BrandUsers = lazy(() => import("views/Brand/BrandUsers"))
const BrandTables = lazy(() => import("views/Brand/Tables"))
const BrandOrders = lazy(() => import("views/Brand/Orders"))
const Locations = lazy(() => import("views/Brand/Locations"))
const BrandDashboard = lazy(() => import("views/Brand/Dashboard/Sales"))
const BrandMonitorDashboard = lazy(() => import("views/Brand/Dashboard/Monitor"))
const BrandTemplates = lazy(() => import("views/Brand/Templates"))
const ItemEditor = lazy(() => import("views/Brand/ItemEditor"))
const SelectedItem = lazy(() => import("views/Brand/ItemEditor/SelectedItem"))
const ModifierEditor = lazy(() => import("views/Brand/ModifierEditor"))
const SelectedModifier = lazy(() => import("views/Brand/ModifierEditor/SelectedModifier"))
const UpsaleEditor = lazy(() => import("views/Brand/UpsaleEditor"))
const PackageEditor = lazy(() => import("views/Brand/PackageEditor"))
const AvailabilityEditor = lazy(() => import("views/Brand/AvailabilityEditor"))
const ModifierAvailabilityEditor = lazy(() => import("views/Brand/ModifierAvailabilityEditor"))
const AvailabilityTemplateEditor = lazy(() => import("views/Brand/AvailabilityTemplateEditor"))
const OpenHours = lazy(() => import("views/Brand/OpenHours"))
const EditLocationTemplates = lazy(() => import("views/Brand/OpenHours/EditLocationTemplates"))
const ItemVat = lazy(() => import("views/Brand/ItemVat"))
const Newsfeed = lazy(() => import("views/Brand/Newsfeed"))
const Test = lazy(() => import("views/Test"))
const LoginScreen = lazy(() => import("views/LoginScreen"))
const Devices = lazy(() => import("views/Brand/Devices"))
const SelectedDevice = lazy(() => import("views/Brand/SelectedDevice"))
const PrepCounters = lazy(() => import("views/Brand/PrepCounters"))
const PaymentAgents = lazy(() => import("views/Brand/Payments/Agents"))
const OnlineProfiles = lazy(() => import("views/Brand/Payments/OnlineProfiles"))
const InvoiceProfiles = lazy(() => import("views/Brand/Payments/InvoiceProfiles"))
const BrandDictionary = lazy(() => import("views/Brand/BrandDictionary"))
const BrandLanguages = lazy(() => import("views/Brand/BrandLanguages"))
const ItemSanityCheck = lazy(() => import("views/Brand/ItemSanityCheck"))
const ErrorLogs = lazy(() => import("views/Brand/ErrorLogs"))
const ReportsSummary = lazy(() => import("views/Brand/Reports/Summary"))
const ReportsPaymentTypes = lazy(() => import("views/Brand/Reports/PaymentTypes"))
const ReportsItemSales = lazy(() => import("views/Brand/Reports/ItemSales"))
const ReportsVatSum = lazy(() => import("views/Brand/Reports/VatSum"))
const ReportsTransactions = lazy(() => import("views/Brand/Reports/Transactions"))
const ReportsDiscounts = lazy(() => import("views/Brand/Reports/Discounts"))
const ReportsUsers = lazy(() => import("views/Brand/Reports/Users"))
const ReportsDevices = lazy(() => import("views/Brand/Reports/Devices"))
const ReportsSelectedItems = lazy(() => import("views/Brand/Reports/SelectedItems"))
const NtakProviders = lazy(() => import("views/Brand/Ntak/Providers"))
const NtakOrders = lazy(() => import("views/Brand/Ntak/Orders"))
const NtakDays = lazy(() => import("views/Brand/Ntak/Days"))
const Content = lazy(() => import("views/Brand/Content"))
const ContentEdit = lazy(() => import("views/Brand/Content/ContentSelector/EditContent"))
const Discount = lazy(() => import("views/Brand/Discount"))
const Printers = lazy(() => import("views/Brand/Printers"))
const ItemExtIdSchema = lazy(() => import("views/Brand/ItemExtIdSchema"))
const ModifierExtIdSchema = lazy(() => import("views/Brand/ModifierExtIdSchema"))
const IntegrationSchemas = lazy(() => import("views/Brand/IntegrationSchemas"))
const Welcome = lazy(()=> import("views/Brand/Welcome"))

function AppRoutes() {
  // if(isMobileOnly) {
  //     return (
  //         <Suspense fallback={<OkeOkeLoading />}>
  //             <Routes>
  //                 <Route exact path="" element={<Navigate replace to="/stat/dashboard" />} />
  //                 <Route exact path="/brand/dashboard" element={<Navigate replace to="/stat/dashboard" />} />
  //                 <Route path="stat">
  //                     <Route path="dashboard" element={<MoBrandDashboard />} />
  //                 </Route>
  //                 <Route path="locations">
  //                     <Route path="dashboard" element={<MoBrandLocations />} />
  //                     <Route path="devices" element={<MoBrandDevices />} />
  //                 </Route>
  //                 <Route path="catalog">
  //                     <Route path="menu" element={<MoBrandMenu />} />
  //                     <Route path="item-availability" element={<MoBrandItemAvailability />} />
  //                     <Route path="modifier-availability" element={<MoBrandModifierAvailability />} />
  //                     <Route path="modifiers" element={<MoBrandModifiers />} />
  //                     <Route path="vat-groups" element={<MoBrandVatGroups />} />
  //                 </Route>
  //                 <Route path="vip">
  //                     <Route path="dashboard" element={<MoVipDashboard />} />
  //                 </Route>
  //                 <Route path="global">
  //                     <Route path="dashboard" element={<MoGlobalDashboard />} />
  //                     <Route path="dictionary" element={<MoGlobalDictionary />} />
  //                 </Route>
  //                 <Route path="reseller">
  //                     <Route path="dashboard" element={<MoResellerDashboard />} />
  //                 </Route>
  //             </Routes>
  //         </Suspense>
  //     )
  // } else {

  return (
    <Suspense fallback={<OkeOkeLoading />}>
      <Routes>
        <Route exact path="" element={<Navigate replace to="/brand/welcome" />} />
        <Route exact path="external">
          <Route exact path="order/:uuid" element={<OrderView />} />
          <Route exact path="create-brand/:code" element={<CreateBrand />} />
          <Route exact path="create-brand/" element={<CreateBrand />} />
          <Route exact path="create-brand-success" element={<CreateBrandSuccess />} />
          <Route exact path="create-brand-error" element={<CreateBrandError />} />
          <Route exact path="brand-status" element={<BrandStatus />} />
          <Route exact path="test" element={<Test />} />
          <Route exact path="downloads" element={<Downloads />} />
          <Route exact path="teya-auth/:response" element={<TeyaAuth />} />
          <Route exact path="teya-auth/" element={<TeyaAuth />} />
        </Route>
        <Route path="registration">
          <Route path="failed" element={<LoginScreen action="failed" />} />
          <Route path="success" element={<LoginScreen action="success" />} />
        </Route>
        <Route path="global">
          <Route path="users" element={<GlobalUsers />} />
          <Route path="resellers" element={<Resellers />} />
          <Route path="dictionary" element={<Dictionary />} />
          <Route path="brand-dictionary" element={<GlobalBrandDictionary />} />
          <Route path="devices" element={<CloudDevices />} />
          <Route path="brand-inits" element={<BrandInits />} />
          <Route path="templates" element={<Templates />} />
          <Route path="brands" element={<GlobalBrands />} />
          <Route path="billing" element={<Billing />} />
          <Route path="global-brand-settings" element={<GlobalBrandSettings />} />
          <Route path="logs" element={<Logs />} />
          <Route path="help" element={<Help />} />
          <Route path="app-editor" element={<AppEditorView />} />
        </Route>
        <Route path="reseller">
          <Route path="brands" element={<GlobalBrands />} />
          <Route path="invitations" element={<ResellerInvitations />} />
          <Route path="commissions" element={<ResellerCommissions />} />
          <Route path="brand-inits" element={<BrandInits />} />
          <Route path="billing" element={<Billing />} />
        </Route>
        <Route path="brand">
          <Route path="dashboard" element={<BrandDashboard />} />
          <Route path="dashboard-monitor" element={<BrandMonitorDashboard />} />
          <Route path="dashboard-sales" element={<BrandDashboard />} />
          <Route path="devices" element={<Devices />} />
          <Route path="devices/:uuid" element={<SelectedDevice />} />
          <Route path="locations" element={<Locations />} />
          <Route path="users" element={<BrandUsers />} />
          <Route path="tables" element={<BrandTables />} />
          <Route path="orders" element={<BrandOrders />} />
          <Route path="templates" element={<BrandTemplates />} />
          <Route path="catalogeditor" element={<Navigate replace to="/brand/menu" />} />
          <Route path="item-extid-schema" element={<ItemExtIdSchema />} />
          <Route path="modifier-extid-schema" element={<ModifierExtIdSchema />} />
          <Route path="menu" element={<ItemEditor />} />
          <Route path="items/:uuid" element={<SelectedItem />} />
          <Route path="modifiers" element={<ModifierEditor />} />
          <Route path="modifiers/:uuid" element={<SelectedModifier />} />
          <Route path="upsales" element={<UpsaleEditor />} />
          <Route path="packages" element={<PackageEditor />} />
          <Route path="prepcounters" element={<PrepCounters />} />
          <Route path="dictionary" element={<BrandDictionary />} />
          <Route path="languages" element={<BrandLanguages />} />
          <Route path="sanity-check" element={<ItemSanityCheck />} />
          <Route path="ntak" element={<Navigate replace to="/brand/ntak-providers" />} />
          <Route path="ntak-providers" element={<NtakProviders />} />
          <Route path="ntak-orders" element={<NtakOrders />} />
          <Route path="ntak-days" element={<NtakDays />} />
          <Route path="payments">
            <Route path="agents" element={<PaymentAgents />} />
            <Route path="online-profiles" element={<OnlineProfiles />} />
            <Route path="invoice-profiles" element={<InvoiceProfiles />} />
          </Route>
          <Route path="item-availability" element={<AvailabilityEditor />} />
          <Route path="modifier-availability" element={<ModifierAvailabilityEditor />} />
          <Route path="availability-template-editor" element={<AvailabilityTemplateEditor />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="open-hours">
            <Route path="" element={<OpenHours />} />
            <Route path="location/:uuid" element={<EditLocationTemplates />} />
          </Route>
          <Route path="errorLogs" element={<ErrorLogs />} />
          <Route path="vat" element={<ItemVat />} />
          <Route path="newsfeed" element={<Newsfeed />} />
          <Route path="reports" element={<ReportsSummary />} />
          <Route path="reports-summary" element={<ReportsSummary />} />
          <Route path="reports-payment-types" element={<ReportsPaymentTypes />} />
          <Route path="reports-item-sales" element={<ReportsItemSales />} />
          <Route path="reports-vat-sum" element={<ReportsVatSum />} />
          <Route path="reports-transactions" element={<ReportsTransactions />} />
          <Route path="reports-discounts" element={<ReportsDiscounts />} />
          <Route path="reports-users" element={<ReportsUsers />} />
          <Route path="reports-devices" element={<ReportsDevices />} />
          <Route path="reports-selected-items" element={<ReportsSelectedItems />} />
          <Route path="contents" element={<Content />} />
          <Route path="contents/:uuid" element={<ContentEdit />} />
          <Route path="discounts" element={<Discount />} />
          <Route path="printers" element={<Printers />} />
          <Route path="integration-schemas" element={<IntegrationSchemas />} />
          <Route path="app-editor" element={<AppEditorView />} />
        </Route>
        <Route path="vip">
          <Route path="dashboard" element={<VipDashboard />} />
          <Route path="coupons" element={<VipCoupons />} />
          <Route path="customers" element={<VipCustomers />} />
          <Route path="purchases" element={<VipPurchases />} />
          <Route path="transactions" element={<VipTransactions />} />
          <Route path="cardTypes" element={<VipCardTypes />} />
          <Route path="customerPanel/:search" element={<CustomerPanel />} />
          <Route path="customerPanel" element={<CustomerPanel />} />
          <Route path="templates" element={<VipTemplates />} />
          <Route path="location-groups" element={<LocationGroups />} />
          <Route path="location-groups/:groupID" element={<SingleGroup />} />
          <Route path="vip-categories" element={<VipCategories />} />
          <Route path="vip-items" element={<VipItemEditor />} />
          <Route path="integration-schemas" element={<VipIntegrationSchemas />} />
          <Route path="stores" element={<VipStores />} />
        </Route>
        <Route path="test" element={<Test />} />
      </Routes>
    </Suspense>
  )
  //}
}

export default AppRoutes
