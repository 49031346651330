import LoadSvg from 'components/LoadSvg-v3'
import moment from 'moment'
import OkeOkeLoading from 'components/OkeOkeLoading'

const OrderStates = {
    BASKET: 0,
    UNCONFIRMED: 5,
    INSERTED: 10,
    SEEN: 20,
    SCHEDULED: 30,
    PENDING: 40,
    PREPARING: 50,
    DONE: 60,
    CLOSED_SUCCESS: 90,
    CLOSED_FAIL: 91
}

function OrderStatusDisplay(props) {

    const { order } = props
    
    const statusSvg = (state) => {
        switch(state) {
            case null: return <div className="loading-container hidden appear"><div className="msg"></div><OkeOkeLoading /></div>
            case OrderStates.INSERTED: return <LoadSvg name='orderStatus1' />
            case OrderStates.SCHEDULED: return <LoadSvg name='orderStatus1' />
            case OrderStates.SEEN: return <LoadSvg name='orderStatus1' /> 
            case OrderStates.PREPARING: return <LoadSvg name='orderStatus2' />
            case OrderStates.DONE: return <LoadSvg name='orderStatus3' />
            case OrderStates.CLOSED_SUCCESS: return <LoadSvg name='orderStatus5' /> 
            case OrderStates.CLOSED_FAIL: return <LoadSvg name='' /> 
            default: return <div className="loading-container hidden appear"><div className="msg"></div><OkeOkeLoading /></div>
        }
    }

    const statusText = (state) => {
        switch(state) {
            case null: return ""
            case OrderStates.INSERTED: return "Order inserted"
            case OrderStates.SCHEDULED: return "Order inserted"
            case OrderStates.PREPARING: return "Order received"
            case OrderStates.DONE: return "Order collectable"
            case OrderStates.CLOSED_SUCCESS: return "Order done"
            default: return ""
        }
    }

    const getEstTime = () => {

        if(order.statuses == null) return null
    
        let time
    
        for(let i=order.statuses.length-1; i>-1; i-- ) {
          if(order.statuses[i].statusID === 202) {
            time = moment(order.statuses[i].insertTimestamp).add(order.statuses[i].notes.minutes, 'm').format('HH:mm')
            break
          }
        }
        
        return time
    }  

    if(order == null) return null

    const orderNumberArray = (order.orderNumber || '').split(['/'])
    const visibleOrderNumber = orderNumberArray[orderNumberArray.length-1] || "N/A"

    return(
        <div className="order-status-root">
            <div className="order-status-header-text"></div>
            {order.orderState !== OrderStates.CLOSED_FAIL && <div className="order-status-svg-container">
                {order.paymentsNeeded < 1 && statusSvg(order.orderState)}
            </div>}
            <div className="status-bar-root">
                <div className={`status-bar animation-shine delay1 ${order.orderState >= OrderStates.INSERTED ? "filled" : ""}`}></div>
                <div className={`status-bar animation-shine delay5 ${order.orderState >= OrderStates.PREPARING ? "filled" : ""}`} ></div>
                <div className={`status-bar animation-shine delay9 ${order.orderState >= OrderStates.DONE ? "filled" : ""}`} ></div>
                <div className={`status-bar animation-shine delay13 ${order.orderState >= OrderStates.CLOSED_SUCCESS ? "filled" : ""}`} ></div>  
            </div>
            <div className="order-status-text font-extraLarge">{statusText(order.orderState)}</div>
            {order.requestedReadyTime != null && <div className="order-status-req-time">Ready time<br/>{moment(order.requestedReadyTime).format('MMMM DD. HH:mm')}</div>}
            {order.orderState === OrderStates.PREPARING && <div className="order-status-est-time">{`Est time: `}{getEstTime()}</div>}
            {/* {order.orderState >= (location?.extOM === 1 ? OrderStates.SEEN : OrderStates.INSERTED) && order.orderState !== OrderStates.CLOSED_FAIL && <div className="order-status-orderNumber hidden appear">{`Order number:`} <br/><span className="order-number">{visibleOrderNumber}</span></div>} */}
            {order.orderState >= OrderStates.INSERTED && order.orderState !== OrderStates.CLOSED_FAIL && <div className="order-status-orderNumber hidden appear">{`Order number:`} <br/><span className="order-number">{visibleOrderNumber}</span></div>}
        </div>
    )
}

export default OrderStatusDisplay