import { createContext, useEffect, useState, useContext } from 'react'
import { LanguageContext } from 'intl/languageContext'

import { globalHelp } from 'apis/global/help'

const HelpContext = createContext()

const transcodeHelps = (items) => {
  let obj = {}
  for(let item of items) {
    obj[item.key] = item
  }
  return obj
}

const HelpProvider = (props) => {
   
  const { language } = useContext(LanguageContext)

  const [helps, setHelps] = useState(JSON.parse(localStorage['ipanel.helps'] || '{}'))
  const [helpsByLang, setHelpsByLang] = useState({})
  const [helpOn, setHelpOn] = useState(localStorage['ipanel.helpOn'] === "0" ? false : true)

  useEffect(() => {
    loadHelps()
  }, [])
  
  const loadHelps = () => {
    globalHelp.getAll().then((result) => {
      if(result.success) {
        let newHelps = transcodeHelps(result.data)
        localStorage['ipanel.helps'] = JSON.stringify(newHelps)
        setHelps(newHelps)
      } else {
        console.log(result)
      }
    })
  }

  useEffect(() => {
    if(language == null) return null

    let obj = {}

    for(let help of Object.values(helps ||{})) {
      obj[help.key] = {
        id: help.id,
        key: help.key,
        content: help?.[language+"Content"] || "Missing content",
        title: help?.[language+"Title"] || "Missing title",
        linkedTopics: (help?.linkedTopics || []).map(linked => {return {
          id: linked.id,
          key: linked.key,
          content: linked?.[language+"Content"] || "Missing content",
          title: linked?.[language+"Title"] || "Missing title",
        }}),
        parentTopics: (help?.parentTopics || []).map(parent => {return {
          id: parent.id,
          key: parent.key,
          content: parent?.[language+"Content"] || "Missing content",
          title: parent?.[language+"Title"] || "Missing title",
        }})
      }
    }

    setHelpsByLang(obj)

  }, [language, helps])

  const handleHelpOn = (value) => {
    setHelpOn(value)
    localStorage['ipanel.helpOn'] = value ? "1" : "0"
  }

  return (
    <HelpContext.Provider
      value={{ helps, helpsByLang, loadHelps, helpOn, handleHelpOn }}
    >
      {props.children}
    </HelpContext.Provider>
  )
}

export { HelpContext, HelpProvider }
