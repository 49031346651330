import moment from 'moment'

const orderStates = {
    0: "In basket",
    10: "Inserted", 
    20: "Seen", 
    30: "Scheduled", 
    40: "Pending", 
    5: "Unconfirmed", 
    50: "Preparing", 
    60: "Done", 
    90: "Closed success",
    91: "Closed fail"
}

function SubmittedOrderHeader(props) {
    
    const { order } = props

    if(order == null) return null

    return(
        <div className="orderHeader-root" >
            <div className="">
                {<div className="order-location font-large">{order.locationName}</div>}
                {/* {<div className="order-table-info font-small">Table name</div>} */}
                {<div className="order-insertTimestamp font-smallest">{moment(order.statuses.filter(status => status.statusID === 100)[0]?.insertTimestamp).format('YYYY.MM.DD. HH:mm')}</div>}
                <div className="order-totalPrice font-large">{`${parseInt(order?.totalPrice || 0)} Ft`}</div>
            </div>
            <div className="font-smallest ml-2">
                <div className="order-orderState-label">{/*getValue("app_v2_orderState_label")*/"Order state:"}</div>
                <div className="order-orderState">{orderStates[order.orderState]}</div>
            </div>
        </div>
    )
}

export default SubmittedOrderHeader