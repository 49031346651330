import axios from 'axios'
import {config} from 'config.js'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage['iPanel-apiToken']
  }
})

export const brandsApi = {
  getBrands: async (filter) => {
    try {
      const axiosconfig = {
        params: {
           filter
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/brands`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
}
