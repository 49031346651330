import LoadSvg from "components/LoadSvg-v3"

export default function CreateBrandSuccess() {

    return(
        <div className="brand-status-root">
            <div className="brand-status-content">
            <LoadSvg name={"barsoftLogo"}/>
            <h1>Sikeres aktiválás!</h1>
            <p>Emailben értesítünk a folyamat végéről.</p>
            <p>Ezt az ablakot bezárhatja.</p>
            </div>
        </div>
    )
}