import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from 'react-device-detect'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <App />
      </DndProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
