import React from "react"

import AccordionContext from './AccordionContext'

function Accordion(props) {

    const { isOpen, setIsOpen, isLoading, className, iconType } = props

    const changeOpenState = (e) => {
        if(typeof setIsOpen === "function") setIsOpen(e)
    }

    let accordionContextValue = {
        isOpen,
        isLoading,
        changeOpenState,
        iconType
    }

    return(
        <AccordionContext.Provider value={accordionContextValue}>
            <div className={`accordion-root ${isOpen ? 'accordion-open' : 'accordion-closed'} ${className}`}>
                {props.children}
            </div>
        </AccordionContext.Provider>
    )
}

export default Accordion