import { useParams } from "react-router-dom"
import useMultilang from "intl/useMultilang"

function TeyaAuth() {
  const { getT } = useMultilang()

  const { response } = useParams()

  return (
    <div className="teya-auth-root" style={{ padding: "24px" }}>
      <h1>
        {response === "success"
          ? getT("teya.auth.success")
          : getT("teya.auth.failed")}
      </h1>
    </div>
  )
}
export default TeyaAuth
