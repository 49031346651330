import { useContext } from 'react'
import { globalHelp } from 'apis/global/help'
import { HelpContext } from 'help/helpContext'

function useHelp() {

  const { helpsByLang, loadHelps } = useContext(HelpContext)

  const getH = (key) => {

    if(helpsByLang?.[key] == null) {
      globalHelp.setBlank(key).then(res => {
        if(res?.success) {
          console.log(res)
        } else {
          console.log(res)
        }
      })
      return {content: "", title: ""}
    } else {
      return helpsByLang?.[key]
    }
  }

  return {
    getH,
    loadHelps
  }
} 

export default useHelp