import React from 'react'

function SubmittedOrderItem(props) {

    const { item } = props

    let selectedSize = null

    if(item == null) return null

    return(
        <div className="submitted-orderItem-root">
            <div className="submitted-orderItem-header">
                <div className="submitted-orderItem-name">
                    <div className="submitted-orderItem-size">{`${item.count} ${selectedSize != null ? selectedSize.name : ""}`}</div>
                    <div className="submitted-orderItem-itemName">{item.name.hu}</div>
                </div>
                <div className="submitted-orderItem-price ml-1">
                    {`${parseInt(item?.totalPrice || 0)} Ft`}
                </div>
            </div>
            {<div className="submitted-orderItem-details font-smallest">
                <div className="submitted-orderItem-notes">{item.notes}</div>
                {/* <div className="submitted-orderItem-modifierGroups">
                    {item.modifierGroups.map(group => <div key={group.uuid} className="modifierGroup-root">
                        <div className="modifierGroup-name">{group.name}</div>
                        {group.modifiers
                        .filter(modifier => modifier.count > 0)
                        .map(modifier => <div key={modifier.uuid} className="modifier-root">
                            <div className="modifier-count">{modifier.count}</div>
                            <div className="modifier-name">{modifier.name}</div>
                            <div className="modifier-price">{modifier.price > 0 ? `+${parseInt(modifier.price)} Ft` : ""}</div>
                        </div>)}
                    </div>)}
                </div> */}
            </div>}
        </div>
    )
}

export default SubmittedOrderItem