import { useState, createContext, useMemo } from "react"

const LoginContext = createContext()

const LoginProvider = (props) => {
  const [user, setUser] = useState(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)

  const value = useMemo(() => ({ user, setUser }), [user])
  const value2 = useMemo(() => ({ loggedIn, setLoggedIn }), [loggedIn])

  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        registration.addEventListener("updatefound", () => {
          const installingWorker = registration.installing
          installingWorker.addEventListener("statechange", () => {
            if (installingWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                const waitingWorker = registration.waiting
                if (waitingWorker) {
                  console.log("Service worker waiting, skip it")
                  // There's a waiting worker, post a message to skip waiting and activate the new worker
                  waitingWorker.postMessage({ type: "SKIP_WAITING" })
                }
                // A new version is available
                setNewVersionAvailable(true)
              } else {
                // The app is newly installed
                console.log("App installed")
              }
            }
          })
        })
      })
  }

  return (
    <LoginContext.Provider value={{ ...value, ...value2, newVersionAvailable }}>
      {props.children}
    </LoginContext.Provider>
  )
}

export { LoginContext, LoginProvider }
