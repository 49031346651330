import LoadSvg from "components/LoadSvg-v3";

export default function CreateBrandError() {
  return (
    <div className="brand-status-root">
      <div className="brand-status-content">
        <LoadSvg name={"barsoftLogo"} />
        <h1>Ez a kód már aktiválva van vagy hibás!</h1>
      </div>
    </div>
  );
}
