import React from "react"

function AccordionBody(props) {

    return(
        <div className="accordion-body">
            {props.children}
        </div>
    )
}

export default AccordionBody