import { useState } from "react"
import ProgressBar from "./ProgressBar"
import Actions from "./Actions"
import Stage from "./Stage"
import { commonApi } from "apis/common"
import "./styles.scss"
import "./styleMobile.scss"

const stages = [
  {
    code: "personal",
  },
  {
    code: "company",
  },
  {
    code: "location",
  },
  {
    code: "sum",
  },
  {
    code: "done",
  },
]

const data = {
  accountOwner: {
    firstName: "",
    lastName: "",
    email: "",
    telNumber: "",
  },
  company: {
    taxNumber: "",
    companyID: "",
    name: "",
    shortName: "",
    address: {
      zip: "",
      city: "",
      floor: "",
      state: "",
      street: "",
      telNumber: "",
      countryCode: "HU",
      streetNumber: "",
    },
  },
  location: {
    name: "",
    description: "",
    address: {
      zip: "",
      city: "",
      floor: "",
      state: "",
      street: "",
      telNumber: "",
      countryCode: "HU",
      streetNumber: "",
    },
    geolocation: {
      lat: "47.5014668",
      lng: "19.0635207",
    },
  },
  options: {
    devices: {
      createPos: true,
    },
  },
}

function CreateBrandWizard(props) {
  const { language, submitData } = props

  const [progressStage, setProgressStage] = useState(0)
  const [formData, setFormData] = useState(data)
  const [errors, setErrors] = useState(null)

  const goToNextStage = () => {
    if (progressStage < stages.length - 1) setProgressStage(progressStage + 1)
  }

  const goToPrevStage = () => {
    if (progressStage > 0) setProgressStage(progressStage - 1)
  }

  const handleFormDataChange = (name, params) => {
    setFormData({ ...formData, [name]: params })
  }

  const getTaxData = (taxNumber) => {
    return commonApi.getTaxData(taxNumber)
  }

  const getZipData = (zip) => {
    return commonApi.getRegionByZip(zip)
  }

  const submit = () => {
    if (typeof submitData === "function") {
      submitData(formData).then((res) => {
        if (res?.success) {
          setProgressStage(stages.length - 1)
        } else {
          console.log(res.data)
          setErrors(res.data?.errors)
        }
      })
    }
  }

  return (
    <div className="create-brand-wizard-root">
      <ProgressBar
        actualStage={progressStage}
        stages={stages}
        language={language}
      />
      <Stage
        actualStage={progressStage}
        stages={stages}
        formData={formData}
        handleFormDataChange={handleFormDataChange}
        getTaxData={getTaxData}
        getZipData={getZipData}
        language={language}
        errors={errors}
      />
      <Actions
        goToNextStage={goToNextStage}
        goToPrevStage={goToPrevStage}
        submitData={submit}
        actualStage={progressStage}
        stages={stages}
        language={language}
        errors={errors}
        resetErrors={() => {
          setErrors(null)
        }}
      />
    </div>
  )
}
export default CreateBrandWizard
