import { useContext, useRef, useEffect, useState } from "react"
import { GlobalContext } from "contexts/globalContext"
import { LoginContext } from "contexts/loginContext"
import { useNavigate, useLocation } from "react-router-dom"
import { ModalContext } from "contexts/modalContext"
import { v4 as uuidv4 } from "uuid"
import Modal from "components/Modal/Modal"
import LoadSvg from "components/LoadSvg-v3"
import navigation from "navigation.json"
import { canUseFeature } from "permissions/appPermission"
import useMultilang from "intl/useMultilang"
import RebuildModal from "./RebuildModal"
import { HelpContext } from "help/helpContext"
import SelectedModule from "./SelectedModule"
import { isMobileOnly } from "react-device-detect"
import InputReactSelect from "components/InputReactSelect"
import { sso } from "apis/sso"
import { toast } from "react-toastify"
import OkeOkeLoading from "components/OkeOkeLoading"

function UserModal() {
  const { user, setLoggedIn } = useContext(LoginContext)
  const { addModal, popModal } = useContext(ModalContext)
  const {
    brand,
    setBrand,
    brands,
    setVipBrand,
    vipBrands,
    setDarkMode,
    darkMode,
  } = useContext(GlobalContext)
  const { helpOn, handleHelpOn } = useContext(HelpContext)
  const { language, setSelectedLanguage, getT } = useMultilang()
  const [anyFeatures, setAnyFeatures] = useState(false)
  const [ntakLoading, setNtakLoading] = useState(false)

  const navigate = useNavigate()
  const brandArray = Object.values(brands || {}).sort((a, b) =>
    a.brandName > b.brandName ? 1 : -1
  )
  const brandCount = brandArray.length
  const vipBrandCount = Object.values(vipBrands || {}).length
  const langRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    if (user == null) return null

    let featureArray = []
    let resellerFeatureArray = []

    for (let item of Object.values(navigation.global.items || {})) {
      if (canUseFeature(user, item?.app, item?.feature)) featureArray.push(item)
    }
    for (let item of Object.values(navigation.reseller.items || {})) {
      if (canUseFeature(user, item?.app, item?.feature))
        resellerFeatureArray.push(item)
    }

    setAnyFeatures(featureArray.length > 0)
  }, [user])

  const handleClickOnBrand = (brandID) => {
    if (brandID != null && brands?.[brandID] != null) {
      setBrand(brands?.[brandID])
      setVipBrand(brands?.[brandID]?.vipBrandID)
      popModal()
    }
  }

  const handleNavigate = (path) => {
    navigate(path)
  }

  const openRebuildModal = () => {
    addModal(
      <Modal key={uuidv4()} className="rebuild-modal" onClickLayout={popModal}>
        <RebuildModal popModal={popModal} />
      </Modal>
    )
  }

  const handleSelectLanguage = (lang) => {
    setSelectedLanguage(lang)
    popModal()
  }

  const openLanguageModal = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="InputFieldMultiSelect-modal"
        onClickLayout={popModal}
        parentRef={langRef}
      >
        <div
          className="language-container"
          onClick={() => handleSelectLanguage("hu")}
        >
          <div className="flag-container hu">
            <img src="https://cdn.okeoke.io/global/flags/hu.svg" alt="huflag" />
          </div>
          <div className="language-name">
            {getT("global.languageSelector.hu")}
          </div>
        </div>
        <div
          className="language-container"
          onClick={() => handleSelectLanguage("en")}
        >
          <div className="flag-container en">
            <img src="https://cdn.okeoke.io/global/flags/gb.svg" alt="enflag" />
          </div>
          <div className="language-name">
            {getT("global.languageSelector.en")}
          </div>
        </div>
      </Modal>
    )
  }

  const openSpecModal = (role) => {
    if (role === "globalAdmin") {
      addModal(
        <Modal key={uuidv4()} onClickLayout={popModal}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/IV84ha3uEGg?controls=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Modal>
      )
    }
  }

  const toggleDarkMode = () => {
    let newValue = localStorage["darkMode"] === "1" ? "0" : "1"
    localStorage["darkMode"] = newValue
    setDarkMode(newValue)
  }

  const toggleHelp = () => {
    handleHelpOn(!helpOn)
  }

  const handleLogout = () => {
    setLoggedIn(false)
    localStorage.clear()
    navigate("/")
    window.location.reload()
  }

  const handleNavigateNTAK = () => {
    if (ntakLoading) return null
    if (brand.id != null) {
      setNtakLoading(true)
      sso
        .createToken(brand.id)
        .then((res) => {
          if (res?.success) {
            let url = `https://ntak.app.barsoft.hu/sso/${res.data.uuid}`
            window.open(url, "_blank", "noreferrer")
          } else {
            console.log(res)
            toast.error("Error")
          }
        })
        .catch((e) => {
          toast.error("Hiba történt, próbáld újra")
          console.log(e)
        })
        .finally(() => setNtakLoading(false))
    }
  }

  return (
    <div className="user-modal">
      <div className="user-data">
        <div className="user-name">{`${user?.lastName || ""} ${
          user?.firstName || ""
        }`}</div>
        <div className="user-email">{user?.email}</div>
        <div className="user-roles font-normal">
          {(user?.roles || []).map((role) => (
            <div
              key={role}
              className="role"
              onClick={() => openSpecModal(role)}
            >
              {getT(`global.roles.${role}`)}
            </div>
          ))}
        </div>
      </div>
      <div className="top-action-btns">
        {brandCount > 1 && (
          <InputReactSelect
            className="brand-selector"
            value={
              brand?.id ? { value: brand.id, label: brand.brandName } : null
            }
            onChange={(e) => handleClickOnBrand(e?.value)}
            options={brandArray?.reduce(
              (options, brand) => [
                ...options,
                { value: brand.id, label: brand.brandName },
              ],
              []
            )}
            placeholder={getT("global.selectBrand")}
          />
        )}
        <div
          className="language-selector"
          ref={langRef}
          onClick={openLanguageModal}
        >
          {language === "en" && (
            <>
              <div className="flag-container en">
                <img
                  src="https://cdn.okeoke.io/global/flags/gb.svg"
                  alt="enflag"
                />
              </div>
            </>
          )}
          {language === "hu" && (
            <>
              <div className="flag-container hu">
                <img
                  src="https://cdn.okeoke.io/global/flags/hu.svg"
                  alt="huflag"
                />
              </div>
            </>
          )}
        </div>
        <div className="bottom-border" />
      </div>
      <div className="action-btns">
        <div
          className={`action-btn help-icon ${helpOn ? "" : "inactive"}`}
          onClick={toggleHelp}
        >
          <img src="/assets/info.png" alt="info" height="19" width="19" />
          <div className="text">Help</div>
        </div>
        <div className="action-btn dark-mode-icon" onClick={toggleDarkMode}>
          <LoadSvg name="darkLight" />
          {darkMode === "1" && <div className="text">Dark mode</div>}
          {darkMode !== "1" && <div className="text">Light mode</div>}
        </div>
        {canUseFeature(user, "brand", "rebuildCache") && (
          <div className="action-btn rebuild-icon" onClick={openRebuildModal}>
            <LoadSvg name="build" />
            <div className="text">Rebuild</div>
          </div>
        )}
        {!isMobileOnly && (
          <>
            <div
              className={`action-btn ${
                location?.pathname.includes("/brand/") ? "selected" : ""
              }`}
              onClick={() => handleNavigate("")}
            >
              <LoadSvg name="brand" />
              Brand
            </div>
            {anyFeatures && (
              <div
                className={`action-btn ${
                  location?.pathname.includes("/global/") ? "selected" : ""
                }`}
                onClick={() => {
                  handleNavigate("/global/brands")
                }}
              >
                <LoadSvg name="global" />
                Global
              </div>
            )}
            {user?.resellerCode && (
              <div
                className={`action-btn ${
                  location?.pathname.includes("/reseller/") ? "selected" : ""
                }`}
                onClick={() => {
                  handleNavigate("/reseller/brands")
                }}
              >
                <LoadSvg name="reseller" />
                Reseller
              </div>
            )}
            {vipBrandCount > 0 && (
              <div
                className={`action-btn ${
                  location?.pathname.includes("/vip/") ? "selected" : ""
                }`}
                onClick={() => handleNavigate("/vip/dashboard")}
              >
                <LoadSvg name="crown" />
                Vip
              </div>
            )}
            <div className={`action-btn`} onClick={() => handleNavigateNTAK()}>
              {!ntakLoading && (
                <>
                  <LoadSvg name="ntak" />
                  NTAK
                </>
              )}
              {ntakLoading && (
                <>
                  <OkeOkeLoading loading={true} />
                </>
              )}
            </div>
          </>
        )}
        <div className="bottom-border" />
      </div>
      {
        <div className="nav-container">
          <SelectedModule module={location.pathname.split("/")[1]} />
        </div>
      }
      <div className="logout-container">
        <div className="logout-btn" onClick={handleLogout}>
          <div className="svg-container">
            <LoadSvg name="exit" />
          </div>
          <div className="logout">{getT("global.navigation.logout")}</div>
        </div>
      </div>
    </div>
  )
}
export default UserModal
