import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import OrderStatus from './OrderStatusDisplay'
import SubmittedOrderHeader from './SubmittedOrderHeader'
import SubmittedOrderItemList from './SubmittedOrderItemList'
import { brandOrders } from 'apis/brand/orders'

function OrderView() {

    const { uuid } = useParams()

    const [order, setOrder] = useState(null)

    useEffect(() => {
        let timer = setInterval(() => {
            refresh()
        }, 3000)

        return () => { clearInterval(timer) }
        // eslint-disable-next-line
    }, [uuid])

    const refresh = () => {
        if(uuid != null) {
            brandOrders.getExternalOrder(uuid).then(res => {
                if(res?.success){
                    setOrder(res.data)
                } else {
                    console.log(res)
                }
            })
        }
    }

    return(
        <>
            <OrderStatus order={order} />
            <SubmittedOrderHeader order={order} />
            <SubmittedOrderItemList order={order} className="orderView-itemList" />
        </>
    )
}

export default OrderView