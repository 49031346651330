import Select from "react-select"

export default function InputReactSelect(props) {
  const { className, value, onChange, options, placeholder, isMulti } = props

  const handleTheme = (theme) => {
    const color1 = "var(--module-color)"
    const color2 = "var(--main-box-color)"
    const color3 = "var(--ipanel-main-text-color)"

    const colors = {
      primary: color1,
      primary75: color3,
      primary50: color3,
      primary25: color1,
      danger: "#DE350B",
      dangerLight: "#FFBDAD",
      neutral0: color2,
      neutral5: color3,
      // neutral10: color3,
      neutral20: color3,
      neutral30: color3,
      neutral40: color3,
      neutral50: color3,
      neutral60: color3,
      neutral70: color3,
      neutral80: color3,
      neutral90: color3,
    }

    return {
      ...theme,
      colors,
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected || state.isFocused ? "var(--panel-text-color)" : "var(--ipanel-main-text-color)",
    }),
  }
  const handleOnChange = (e) => {
    if (typeof onChange === "function") {
      onChange(e)
    }
  }

  return (
    <Select
      isMulti={isMulti}
      styles={customStyles}
      theme={handleTheme}
      className={className}
      value={value ? value : null}
      onChange={handleOnChange}
      options={options}
      placeholder={placeholder}
    />
  )
}
