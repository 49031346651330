import dictionary from "./dictionary"

function ProgressBar(props) {
  const { actualStage, stages, language } = props

  return (
    <div className="progress-bar-root">
      {stages.map((stage, index) => (
        <div
          key={index}
          className={`stage-container ${
            actualStage >= index ? "selected" : ""
          }`}
        >
          <div className="stage-name">
            {dictionary?.[stage?.code]?.[language]}
          </div>
          {/* <div className="stage-circle"></div> */}
        </div>
      ))}
      {/* <div className="horizontal-line"></div> */}
    </div>
  )
}
export default ProgressBar
