import { useContext } from 'react'
import LoadSvg3 from 'components/LoadSvg-v3'
import useMultilang from "intl/useMultilang"
import useHelp from "help/useHelp"
import { ModalContext } from "contexts/modalContext"
import { v4 as uuidv4 } from "uuid"
import Modal from "components/Modal/Modal"
import LinkedTopicAccordion from "components/LinkedTopicAccordion"
import { useLocation } from 'react-router-dom'
import { HelpContext } from 'help/helpContext'
import parse from 'html-react-parser'

function IconButton(props) {

  const { className, loading, disabled, handleOnClick, type, svgName, btnRef } = props

  const { getT } = useMultilang()

  return(
      <div
        className={`btn ${loading ? "loading" : ""} with-svg ${className == null ? "" : className} ${disabled === true ? "disabled" : ""}`}
        onClick={(e)=>{handleOnClick(e)}}
        ref={btnRef}
      >
        {!loading && <div className="svg-container"><LoadSvg3 name={svgName} /></div>}
        {loading != null && loading && <div className="svg-container load-icon animated rotate"><LoadSvg3 name="okeOkeIcon" /></div>}
        <div className="btn-text">{getT(`components.button.${type}`)}</div>
      </div>
  )
}

function ButtonSimple(props) {
  const { 
    svg, 
    svgName,
    text,
    onClick, 
    className,
    disabled,
    loading,
    type,
    btnRef 
  } = props

  const { addModal, popModal } = useContext(ModalContext)
  const { helpOn } = useContext(HelpContext)
  const location = useLocation()
  const { getH } = useHelp()

  const handleOnClick = (e) => {
    if((disabled == null || disabled === false) && !loading && typeof onClick === "function") onClick(e)
    if(type === "help") {
      let topic = getH(`page${location.pathname.split("/").join(".")}`)
      addModal(
        <Modal key={uuidv4()} className="help-modal" onClickLayout={popModal}>
          <h1>{topic.title}</h1>
          {parse(topic?.content || <></>)}
          {(topic?.linkedTopics || []).map(topic => <LinkedTopicAccordion key={topic.id} topic={topic} />)}
        </Modal>
      )
    }
  }

  switch(type) {
    case 'edit': return <IconButton svgName="pencil" className="btn-black" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef}/>
    case 'clear': return <IconButton svgName="trash" className="btn-red" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'filter': return <IconButton svgName="filter" className="btn-black" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'fields': return <IconButton svgName="fields" className="btn-green" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'editOrder': return <IconButton svgName="order" className="btn-orange" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'createNew': return <IconButton svgName="plus" className="btn-blue" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'back': return <IconButton svgName="back" className="btn-red" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'help': return helpOn ? <IconButton svgName="info" className="btn-blue" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} /> : null
    case 'save': return <IconButton svgName="circleCheck" className="btn-yes" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'cancel': return <IconButton svgName="circleX" className="btn-red" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'selectAll': return <IconButton svgName="selectAll" className="btn-small" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    case 'selectNone': return <IconButton svgName="selectNone" className="btn-small" type={type} loading={loading} disabled={disabled} handleOnClick={(e)=>{handleOnClick(e)}} btnRef={btnRef} />
    default:
    return (
      <div
        className={`btn ${loading ? "loading" : ""} ${(svg || svgName || loading) ? "with-svg" : ""} ${className == null ? "" : className} ${disabled === true ? "disabled" : ""}`}
        onClick={(e)=>{handleOnClick(e)}}
        ref={btnRef}
      >
        {svg != null && !loading && <div className="svg-container">{svg}</div>}
        {svgName != null && !loading && <div className="svg-container"><LoadSvg3 name={svgName} /></div>}
        {loading != null && loading && <div className="svg-container load-icon animated rotate"><LoadSvg3 name="okeOkeIcon" /></div>}
        <div className="btn-text">{text}</div>
      </div>
    )
  }
}

export default ButtonSimple