import axios from 'axios'
import {config} from 'config.js'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage['iPanel-apiToken']
  }
})

export const catalogControl = {
  getRebuild: async (brandID) => {
    try {
      const axiosconfig = {
        params: {
          brandID
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/brand/catalog/control/rebuild`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  setRebuild: async (brandID) => {
    let body = {
        brandID
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/brand/catalog/control/rebuild`, body)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
}