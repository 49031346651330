import { useState } from 'react'
import Accordion from 'components/Accordion'
import AccordionHead from 'components/Accordion/AccordionHead'
import AccordionBody from 'components/Accordion/AccordionBody'
import parse from 'html-react-parser'

function LinkedTopicAccordion(props) {

    const { topic } = props

    const [isOpen, setIsOpen] = useState(false)

    return(
        <Accordion 
            className={`linked-topic-accordion`}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            iconType={null}
        >
            <AccordionHead onClick={() => { setIsOpen(!isOpen) }}>
                <div className="accordion-header-label" >{`${topic.title}`}</div>
            </AccordionHead>
            <AccordionBody>
                {parse(topic?.content || <></>)}
            </AccordionBody>
        </Accordion>    
    )
}
export default LinkedTopicAccordion