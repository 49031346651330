import { useState, useEffect } from "react"
import dictionary from "../dictionary"

function AccountOwner(props) {
  const { formData, handleFormDataChange, language } = props

  const [localData, setLocalData] = useState(formData)

  useEffect(() => {
    setLocalData(formData)
  }, [formData])

  const handleChange = (e) => {
    setLocalData({ ...localData, [e.target.name]: e.target.value })
  }

  const handleBlur = (e) => {
    handleFormDataChange("accountOwner", {
      ...localData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div className="stage-container account-owner">
      {Object.keys(formData || {}).map((data) => (
        <div key={data}>
          <div className="input-label">{dictionary?.[data]?.[language]}</div>
          <input
            name={data}
            value={localData?.[data]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      ))}
    </div>
  )
}
export default AccountOwner
