import { useContext } from 'react'
import ReactCodeInput from 'react-code-input'
import ButtonSimple from "components/Buttons/ButtonSimple"
import { globalAuth } from 'apis/globalAuth'
import { toast } from 'react-toastify'
import { LoginContext } from 'contexts/loginContext'
import useMultilang from 'intl/useMultilang'

function Pin(props) {

    const { setScreenContent } = props
    const { setLoggedIn, setUser } = useContext(LoginContext)
    const { getT } = useMultilang()

    const handleChangePin = (pin) => {
    
        if(pin.length === 4) {
          globalAuth.generateUserToken(localStorage['username'], pin).then((res) => {
            if (res?.success) {
              toast.success(getT('global.login.success'))
              localStorage['iPanel-apiToken'] = res.data.token
              setUser(res.data.user)
              setLoggedIn(true)
              localStorage.removeItem('username')
              window.location.reload()
            } else {
              toast.error(getT('global.login.invalidPin'), { autoClose: 2000, hideProgressBar: false })
            }
          }).catch((e) => {
            console.error(e);
          })
        }
    }

    return(
        <div className="login-fields">
            <ReactCodeInput type="number" fields={4} onChange={handleChangePin} inputMode={"numeric"}/>
            <ButtonSimple 
            className="btn-brand-color btn-big" 
            text={getT('global.login.login')} 
            onClick={() => { setScreenContent("login") }}
            />
        </div>
    )
}
export default Pin