import { LoginContext } from "contexts/loginContext"
import { useState, useContext, useEffect } from "react"
import ButtonSimple from "components/Buttons/ButtonSimple"
import TextField from "components/TextField"
import { globalAuth } from "apis/globalAuth"
import { toast } from "react-toastify"
import useMultilang from "intl/useMultilang"
import LoadingComponent from "components/LoadingComponent"

function Login(props) {
  const { setScreenContent } = props

  const { setLoggedIn, setUser } = useContext(LoginContext)

  const [email, setEmail] = useState(
    localStorage["username"] != null ? localStorage["username"] : ""
  )
  const { getT } = useMultilang()
  const [loginLoading, setLoginLoading] = useState(false)

  function enterFunction(e) {
    if (e.key === "Enter") {
      handleClickOnNext()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", enterFunction, false)

    return () => {
      document.removeEventListener("keydown", enterFunction)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setLoginLoading(true)
    globalAuth
      .validateUserToken()
      .then((res) => {
        if (res?.success) {
          setUser(res.data)
          setLoggedIn(true)
          document.removeEventListener("keydown", enterFunction)
        } else {
          //toast.error('Invalid token!')
          setLoggedIn(false)
        }
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => setLoginLoading(false))
    // eslint-disable-next-line
  }, [setLoggedIn, setUser])

  const handleClickOnNext = () => {
    globalAuth
      .generateUserPin(localStorage["username"])
      .then((res) => {
        if (res?.success) {
          toast.success(getT("global.login.success"))
          setScreenContent("pin")
        } else {
          toast.error("Error! " + res.message, {
            autoClose: 2000,
            hideProgressBar: false,
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    if (e.target.value !== "") {
      localStorage["username"] = e.target.value
    } else localStorage.removeItem("username")
  }

  return (
    <>
      <LoadingComponent isLoading={loginLoading} />
      <div className="login-fields">
        <TextField
          label={getT("global.login.email")}
          value={email}
          className="login-email"
          onChange={handleChangeEmail}
        />
        <ButtonSimple
          className="btn-vip-color btn-big mb-2"
          text={getT("global.login.next")}
          onClick={handleClickOnNext}
        />
      </div>
    </>
  )
}
export default Login
