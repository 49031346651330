import SubmittedOrderItem from './SubmittedOrderItem'

function SubmittedOrderItemList(props) {

    const { order } = props

    if(order == null) return null

    return(
        <div className={`submitted-orderItemList-root`}>
            {order.items
            .filter(item => item.count > 0)
            .map(item => <SubmittedOrderItem key={item.orderItemUUID} item={item} />)}
        </div>
    )
}

export default SubmittedOrderItemList