import dictionary from '../dictionary'

function Summary(props) {

    const { formData, language } = props

    return(
        <div className="summary-root">
            <div className="data-summary-box">
                <div className="data-title">{dictionary?.['personal']?.[language]}</div>
                <div className="data-fields">
                    {Object.keys(formData?.accountOwner || {}).map(data => 
                        <div key={data} className="data-field">
                            <div className="data-field-title">{dictionary?.[data]?.[language]}:</div>
                            <div className="data-field-value">{formData?.accountOwner[data]}</div>
                        </div>    
                    )}
                </div>
            </div>
            <div className="data-summary-box">
                <div className="data-title">{dictionary?.['company']?.[language]}</div>
                <div className="data-fields">
                    <div className="data-field">
                        <div className="data-field-title">{dictionary?.name?.[language]}:</div>
                        <div className="data-field-value">{formData?.company.name}</div>
                    </div>   
                    <div className="data-field">
                        <div className="data-field-title">{dictionary?.address?.[language]}:</div>
                        <div className="data-field-value">{`${formData?.company?.address?.zip} ${formData?.company?.address?.city} ${formData?.company?.address?.street} ${formData?.company?.address?.streetNumber} ${formData?.company?.address?.floor}`}</div>
                    </div>    
                </div>
            </div>
            <div className="data-summary-box">
                <div className="data-title">{dictionary?.['location']?.[language]}</div>
                <div className="data-fields">
                    <div className="data-field">
                        <div className="data-field-title">{dictionary?.name?.[language]}:</div>
                        <div className="data-field-value">{formData?.location?.name}</div>
                    </div>  
                    <div className="data-field">
                        <div className="data-field-title">{dictionary?.description?.[language]}:</div>
                        <div className="data-field-value">{formData?.location?.description}</div>
                    </div>  
                    <div className="data-field">
                        <div className="data-field-title">{dictionary?.address?.[language]}:</div>
                        <div className="data-field-value">{`${formData?.location?.address?.state} ${formData?.location?.address?.zip} ${formData?.location?.address?.city} ${formData?.location?.address?.street} ${formData?.location?.address?.streetNumber} ${formData?.location?.address?.floor}`}</div>
                    </div>    
                </div>
            </div>
        </div>
    )
}
export default Summary