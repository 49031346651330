import OrderStatus1 from "assets/svg3/status/OrderStatus_1.svg"
import OrderStatus2 from "assets/svg3/status/OrderStatus_2.svg"
import OrderStatus3 from "assets/svg3/status/OrderStatus_3.svg"
import OrderStatus3b from "assets/svg3/status/OrderStatus_3b.svg"
import OrderStatus5 from "assets/svg3/status/OrderStatus_5.svg"
import { ReactComponent as OrderStatus1b } from "assets/svg3/status/orderStatus1b.svg"
import { ReactComponent as OrderStatus2b } from "assets/svg3/status/orderStatus2b.svg"
import { ReactComponent as OrderStatus3bb } from "assets/svg3/status/orderStatus3b.svg"
import { ReactComponent as OrderStatus5b } from "assets/svg3/status/orderStatus5b.svg"
import { ReactComponent as OrderStatusDelete } from "assets/svg3/status/orderStatusDelete.svg"

import { ReactComponent as Meat } from "assets/svg3/common/meat.svg"
import { ReactComponent as Milk } from "assets/svg3/common/milk.svg"
import { ReactComponent as GlutenFree } from "assets/svg3/common/sensegluten.svg"
import { ReactComponent as Vegan } from "assets/svg3/common/vegan.svg"

import { ReactComponent as CircleCheck } from "assets/svg3/iPanel/akar-icons_circle-check2.svg"
import { ReactComponent as CirclePlus } from "assets/svg3/iPanel/akar-icons_circle-plus.svg"
import { ReactComponent as CircleX } from "assets/svg3/iPanel/akar-icons_circle-x2.svg"
import { ReactComponent as Modifiers } from "assets/svg3/iPanel/carbon_category-new-each.svg"
import { ReactComponent as Filter } from "assets/svg3/iPanel/charm_filter.svg"
import { ReactComponent as Upsell } from "assets/svg3/iPanel/cil_chevron-double-up.svg"
import { ReactComponent as Pencil } from "assets/svg3/iPanel/cil_pencil.svg"
import { ReactComponent as Category } from "assets/svg3/iPanel/clarity_list-line2.svg"
import { ReactComponent as Image } from "assets/svg3/iPanel/clarity_image-line.svg"
import { ReactComponent as English } from "assets/svg3/iPanel/english.svg"
import { ReactComponent as Hungary } from "assets/svg3/iPanel/hu.svg"
import { ReactComponent as Description } from "assets/svg3/iPanel/descIcon.svg"
import { ReactComponent as MoreDots } from "assets/svg3/iPanel/moreDots.svg"
import { ReactComponent as Group } from "assets/svg3/iPanel/group.svg"
import { ReactComponent as Chevron } from "assets/svg3/iPanel/chevronSquare.svg"
import { ReactComponent as RoundedArrow } from "assets/svg3/iPanel/rounded_arrow.svg"
import { ReactComponent as Cross } from "assets/svg3/iPanel/cross.svg"
import { ReactComponent as OkeOkeIcon } from "assets/svg3/iPanel/okeOkeIcon.svg"
import { ReactComponent as SortIconNone } from "assets/svg3/iPanel/sortIconNone.svg"
import { ReactComponent as SortIconUp } from "assets/svg3/iPanel/sortIconUp.svg"
import { ReactComponent as SortIconDown } from "assets/svg3/iPanel/sortIconDown.svg"
import { ReactComponent as GridDots } from "assets/svg3/iPanel/grid_dots.svg"
import { ReactComponent as Check } from "assets/svg3/iPanel/check.svg"
import { ReactComponent as Reply } from "assets/svg3/iPanel/mdi_reply-all.svg"
import { ReactComponent as Brand } from "assets/svg3/iPanel/brand.svg"
import { ReactComponent as Global } from "assets/svg3/iPanel/global.svg"
import { ReactComponent as Exit } from "assets/svg3/iPanel/exit.svg"
import { ReactComponent as Info } from "assets/svg3/iPanel/akar-icons_info-fill.svg"
import { ReactComponent as Plugged } from "assets/svg3/iPanel/plugged.svg"
import { ReactComponent as UnPlugged } from "assets/svg3/iPanel/unplugged.svg"
import { ReactComponent as Link } from "assets/svg3/iPanel/entypo_link.svg"
import { ReactComponent as PayAtCounter } from "assets/svg3/iPanel/payAtCounter.svg"
import { ReactComponent as Store } from "assets/svg3/iPanel/store.svg"
import { ReactComponent as Build } from "assets/svg3/iPanel/build.svg"
import { ReactComponent as Home } from "assets/svg3/iPanel/bx_home.svg"
import { ReactComponent as Search } from "assets/svg3/iPanel/search.svg"
import { ReactComponent as Trash } from "assets/svg3/iPanel/akar-icons_trash-can.svg"
import { ReactComponent as Back } from "assets/svg3/iPanel/eva_arrow-ios-back-fill.svg"
import { ReactComponent as Plus } from "assets/svg3/iPanel/charm_plus.svg"
import { ReactComponent as Order } from "assets/svg3/iPanel/fe_list-order.svg"
import { ReactComponent as Fields } from "assets/svg3/iPanel/fields.svg"
import { ReactComponent as OpenHours } from "assets/svg3/iPanel/openHours.svg"
import { ReactComponent as Settings } from "assets/svg3/iPanel/clarity_settings-line.svg"
import { ReactComponent as Catalog } from "assets/svg3/iPanel/catalog.svg"
import { ReactComponent as Devices } from "assets/svg3/iPanel/devices.svg"
import { ReactComponent as Locations } from "assets/svg3/iPanel/locations.svg"
import { ReactComponent as News } from "assets/svg3/iPanel/news.svg"
import { ReactComponent as Orders } from "assets/svg3/iPanel/orders.svg"
import { ReactComponent as Payments } from "assets/svg3/iPanel/payments.svg"
import { ReactComponent as QR } from "assets/svg3/iPanel/QR.svg"
import { ReactComponent as Templates } from "assets/svg3/iPanel/templates.svg"
import { ReactComponent as Users } from "assets/svg3/iPanel/users.svg"
import { ReactComponent as SubMenu } from "assets/svg3/iPanel/submenu.svg"
import { ReactComponent as Dictionary } from "assets/svg3/iPanel/dictionary.svg"
import { ReactComponent as Cloud } from "assets/svg3/iPanel/akar-icons_cloud.svg"
import { ReactComponent as DarkLight } from "assets/svg3/iPanel/mdi_theme-light-dark.svg"
import { ReactComponent as Tablet } from "assets/svg3/iPanel/akar-icons_tablet-device.svg"
import { ReactComponent as Kiosk } from "assets/svg3/iPanel/kiosk.svg"
import { ReactComponent as Pos } from "assets/svg3/iPanel/pos.svg"
import { ReactComponent as SelectNone } from "assets/svg3/iPanel/fluent_select-all-off-24-regular.svg"
import { ReactComponent as SelectAll } from "assets/svg3/iPanel/fluent_select-all-on-24-regular.svg"
import { ReactComponent as CheckList } from "assets/svg3/iPanel/bi_list-check.svg"
import { ReactComponent as DivIcon } from "assets/svg3/iPanel/bi_code-slash.svg"
import { ReactComponent as Reseller } from "assets/svg3/iPanel/ep_sell.svg"
import { ReactComponent as Invite } from "assets/svg3/iPanel/mingcute_invite-line.svg"
import { ReactComponent as MultiCash } from "assets/svg3/iPanel/mdi_cash-multiple.svg"
import { ReactComponent as Crown } from "assets/svg3/iPanel/ri_vip-crown-2-line.svg"
import { ReactComponent as ColorPalette } from "assets/svg3/iPanel/eva_color-palette-outline.svg"
import { ReactComponent as BorderRadius } from "assets/svg3/iPanel/bx_border-radius.svg"
import { ReactComponent as BrandApp } from "assets/svg3/iPanel/akar-icons_mobile-device.svg"
import { ReactComponent as Ntak } from "assets/svg3/iPanel/ntak.svg"
import { ReactComponent as Upload } from "assets/svg3/iPanel/dashicons_upload.svg"
import { ReactComponent as Error } from "assets/svg3/iPanel/bx_error-alt.svg"
import { ReactComponent as Reports } from "assets/svg3/iPanel/mdi_report-areaspline.svg"
import { ReactComponent as Print } from "assets/svg3/iPanel/cil_print.svg"
import { ReactComponent as Integration } from "assets/svg3/iPanel/icon-park-outline_connection-point.svg"

import { ReactComponent as Bug } from "assets/svg3/brandApp/bug.svg"

import { ReactComponent as Set1Plus } from "assets/svg3/set1/charm_plus.svg"
import { ReactComponent as Set1Minus } from "assets/svg3/set1/akar-icons_minus.svg"
import { ReactComponent as Set1CircleCheck } from "assets/svg3/set1/akar-icons_circle-check.svg"
import { ReactComponent as Set1CircleX } from "assets/svg3/set1/akar-icons_circle-x.svg"
import { ReactComponent as Set1Location } from "assets/svg3/set1/akar-icons_location.svg"
import { ReactComponent as Set1More } from "assets/svg3/set1/akar-icons_more-horizontal.svg"
import { ReactComponent as Set1Tag } from "assets/svg3/set1/akar-icons_tag.svg"
import { ReactComponent as Set1Trash } from "assets/svg3/set1/akar-icons_trash-can.svg"
import { ReactComponent as Set1Global } from "assets/svg3/set1/ant-design_global-outlined.svg"
import { ReactComponent as Set1Cart } from "assets/svg3/set1/ant-design_shopping-cart-outlined.svg"
import { ReactComponent as Set1User } from "assets/svg3/set1/ant-design_user-outlined.svg"
import { ReactComponent as Set1CreditCard } from "assets/svg3/set1/bi_credit-card-2-front.svg"
import { ReactComponent as Set1Home } from "assets/svg3/set1/bx_home.svg"
import { ReactComponent as Set1List } from "assets/svg3/set1/clarity_list-line.svg"
import { ReactComponent as Set1Back } from "assets/svg3/set1/eva_arrow-ios-back-fill.svg"
import { ReactComponent as Set1Info } from "assets/svg3/set1/eva_info-outline.svg"
import { ReactComponent as Set1History } from "assets/svg3/set1/fluent_history-32-filled.svg"
import { ReactComponent as Set1Scan } from "assets/svg3/set1/fluent_scan-dash-32-regular.svg"
import { ReactComponent as Set1Coupon } from "assets/svg3/set1/ri_coupon-3-line.svg"
import { ReactComponent as Set1Discount } from "assets/svg3/set1/tabler_discount-2.svg"
import { ReactComponent as Set1Pencil } from "assets/svg3/set1/pencil.svg"
import { ReactComponent as Set1Close } from "assets/svg3/set1/close.svg"
import { ReactComponent as Set1Invoice } from "assets/svg3/set1/nimbus_invoice.svg"
import { ReactComponent as Set1Payments } from "assets/svg3/set1/ic_baseline-payments.svg"
import { ReactComponent as Set1Check } from "assets/svg3/set1/check.svg"
import { ReactComponent as Set1News } from "assets/svg3/set1/news.svg"
import { ReactComponent as Content } from "assets/svg3/iPanel/content.svg"
import { ReactComponent as Discount } from "assets/svg3/iPanel/discount.svg"
import { ReactComponent as CustomSettings } from "assets/svg3/iPanel/customSettings.svg"
import { ReactComponent as Layouts } from "assets/svg3/iPanel/layouts.svg"
import { ReactComponent as SelectedElement } from "assets/svg3/iPanel/selectedElement.svg"
import { ReactComponent as Construction } from "assets/svg3/iPanel/noto_construction.svg"
import { ReactComponent as UploadCloud } from "assets/svg3/iPanel/upload-cloud.svg"
import { ReactComponent as QrCode } from "assets/svg3/iPanel/qrcode.svg"
import { ReactComponent as DollarInBox } from "assets/svg3/iPanel/dollar-in-box.svg"
import { ReactComponent as TagInBox } from "assets/svg3/iPanel/tag-in-box.svg"
import { ReactComponent as BasketInBox } from "assets/svg3/iPanel/basket-in-box.svg"
import { ReactComponent as Datum } from "assets/svg3/iPanel/datum.svg"
import { ReactComponent as LocationMarker } from "assets/svg3/iPanel/location-marker.svg"
import { ReactComponent as QrcodeFrame } from "assets/svg3/iPanel/qrcodeFrame.svg"
import { ReactComponent as Dot } from "assets/svg3/iPanel/dot.svg"
import { ReactComponent as InternetAntenna } from "assets/svg3/iPanel/internet-antenna.svg"
import { ReactComponent as Alert } from "assets/svg3/iPanel/alert.svg"
import { ReactComponent as MoreHorizontal } from "assets/svg3/iPanel/more-horizontal.svg"
import { ReactComponent as BookOpen } from "assets/svg3/iPanel/book-open.svg"
import { ReactComponent as Reload } from "assets/svg3/iPanel/reload.svg"
import { ReactComponent as Edit } from "assets/svg3/iPanel/edit.svg"
import { ReactComponent as TaskEdit } from "assets/svg3/iPanel/task-edit.svg"
import { ReactComponent as BarsoftLogo } from "assets/svg3/iPanel/barsoftlogo.svg"
import { ReactComponent as Video } from "assets/svg3/iPanel/video.svg"
import { ReactComponent as SvgFile } from "assets/svg3/iPanel/svgFile.svg"
import { ReactComponent as BarShop } from "assets/svg3/iPanel/barshop.svg"
import { ReactComponent as Lock } from "assets/svg3/iPanel/lock.svg"
import { ReactComponent as CashRegister } from "assets/svg3/iPanel/cash-register.svg"
import { ReactComponent as BrandLogo } from "assets/svg3/iPanel/brand-logo.svg"

function Container(props) {
  const { className, onClick, refProp } = props

  return (
    <div ref={refProp} className={`svg-root ${className != null ? className : ""}`} onClick={onClick}>
      {props.children}
    </div>
  )
}

function LoadSvg(props) {
  const { name, onClick, set, className, refProp } = props

  const handleClick = (e) => {
    if (typeof onClick === "function") onClick(e)
  }

  if (name == null) return null

  const icons = {
    default: {
      orderStatus1: (
        <object className="hidden appear" type="image/svg+xml" data={OrderStatus1}>
          svg-animation
        </object>
      ),
      orderStatus2: (
        <object className="hidden appear" type="image/svg+xml" data={OrderStatus2}>
          svg-animation
        </object>
      ),
      orderStatus3: (
        <object className="hidden appear" type="image/svg+xml" data={OrderStatus3}>
          svg-animation
        </object>
      ),
      orderStatus3b: (
        <object className="hidden appear" type="image/svg+xml" data={OrderStatus3b}>
          svg-animation
        </object>
      ),
      orderStatus5: (
        <object className="hidden appear" type="image/svg+xml" data={OrderStatus5}>
          svg-animation
        </object>
      ),
      orderStatus1b: <OrderStatus1b />,
      orderStatus2b: <OrderStatus2b />,
      orderStatus3bb: <OrderStatus3bb />,
      orderStatus5b: <OrderStatus5b />,
      orderStatusDelete: <OrderStatusDelete />,
      circleCheck: <CircleCheck />,
      circlePlus: <CirclePlus />,
      circleX: <CircleX />,
      category: <Category />,
      filter: <Filter />,
      upsell: <Upsell />,
      pencil: <Pencil />,
      modifiers: <Modifiers />,
      image: <Image />,
      english: <English />,
      hungary: <Hungary />,
      description: <Description />,
      moreDots: <MoreDots />,
      group: <Group />,
      chevron: <Chevron />,
      roundedArrow: <RoundedArrow />,
      cross: <Cross />,
      okeOkeIcon: <OkeOkeIcon />,
      sortIconNone: <SortIconNone />,
      sortIconUp: <SortIconUp />,
      sortIconDown: <SortIconDown />,
      gridDots: <GridDots />,
      check: <Check />,
      reply: <Reply />,
      brand: <Brand />,
      global: <Global />,
      exit: <Exit />,
      info: <Info />,
      plugged: <Plugged />,
      unPlugged: <UnPlugged />,
      link: <Link />,
      payAtCounter: <PayAtCounter />,
      store: <Store />,
      build: <Build />,
      home: <Home />,
      search: <Search />,
      trash: <Trash />,
      error: <Error />,
      back: <Back />,
      plus: <Plus />,
      order: <Order />,
      fields: <Fields />,
      openHours: <OpenHours />,
      settings: <Settings />,
      catalog: <Catalog />,
      devices: <Devices />,
      locations: <Locations />,
      news: <News />,
      payments: <Payments />,
      qr: <QR />,
      templates: <Templates />,
      orders: <Orders />,
      subMenu: <SubMenu />,
      dictionary: <Dictionary />,
      bug: <Bug />,
      users: <Users />,
      darkLight: <DarkLight />,
      cloud: <Cloud />,
      tablet: <Tablet />,
      kiosk: <Kiosk />,
      pos: <Pos />,
      checkList: <CheckList />,
      divIcon: <DivIcon />,
      selectAll: <SelectAll />,
      selectNone: <SelectNone />,
      meat: <Meat />,
      milk: <Milk />,
      glutenFree: <GlutenFree />,
      vegan: <Vegan />,
      reseller: <Reseller />,
      invite: <Invite />,
      multiCash: <MultiCash />,
      crown: <Crown />,
      colorPalette: <ColorPalette />,
      borderRadius: <BorderRadius />,
      brandApp: <BrandApp />,
      ntak: <Ntak />,
      upload: <Upload />,
      reports: <Reports />,
      print: <Print />,
      setPlus: <Set1Plus />,
      setMinus: <Set1Minus />,
      setCircleCheck: <Set1CircleCheck />,
      setCircleX: <Set1CircleX />,
      setLocation: <Set1Location />,
      setMore: <Set1More />,
      setTag: <Set1Tag />,
      setTrash: <Set1Trash />,
      setGlobal: <Set1Global />,
      setCart: <Set1Cart />,
      setUser: <Set1User />,
      setCreditCard: <Set1CreditCard />,
      setHome: <Set1Home />,
      setList: <Set1List />,
      setBack: <Set1Back />,
      setPencil: <Set1Pencil />,
      setInfo: <Set1Info />,
      setHistory: <Set1History />,
      setScan: <Set1Scan />,
      setCoupon: <Set1Coupon />,
      setClose: <Set1Close />,
      setInvoice: <Set1Invoice />,
      setPayments: <Set1Payments />,
      setCheck: <Set1Check />,
      setNews: <Set1News />,
      setDiscount: <Set1Discount />,
      content: <Content />,
      discount: <Discount />,
      integration: <Integration />,
      customSettings: <CustomSettings />,
      layouts: <Layouts />,
      selectedElement: <SelectedElement />,
      construction: <Construction />,
      uploadCloud: <UploadCloud />,
      qrCode: <QrCode />,
      dollarInBox: <DollarInBox />,
      tagInBox: <TagInBox />,
      basketInBox: <BasketInBox />,
      datum: <Datum />,
      locationMarker: <LocationMarker />,
      qrcodeFrame: <QrcodeFrame />,
      dot: <Dot />,
      internetAntenna: <InternetAntenna />,
      alert: <Alert />,
      moreHorizontal: <MoreHorizontal />,
      bookOpen: <BookOpen />,
      reload: <Reload />,
      edit: <Edit />,
      taskEdit: <TaskEdit />,
      barsoftLogo: <BarsoftLogo />,
      video: <Video />,
      svgFile: <SvgFile />,
      barShop: <BarShop />,
      lock: <Lock />,
      cashRegister: <CashRegister />,
      brandLogo: <BrandLogo />      
    },
  }  

  return (
    <Container className={className} onClick={handleClick} refProp={refProp}>
      {icons?.[set == null ? "default" : set]?.[name] ? icons?.[set == null ? "default" : set]?.[name] : <CircleX />}
    </Container>
  )
}

export default LoadSvg
