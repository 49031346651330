import AccountOwner from "./Stages/AccountOwner"
import Company from "./Stages/Company"
import Location from "./Stages/Location"
import Summary from "./Stages/Summary"
import Done from "./Stages/Done"
import useMultilang from "intl/useMultilang"

function Stage(props) {
  const {
    actualStage,
    stages,
    formData,
    handleFormDataChange,
    language,
    getTaxData,
    getZipData,
    errors,
  } = props

  const { getT } = useMultilang()

  return (
    <div className="stages-container">
      {(errors == null || errors.length < 1) && (
        <>
          {stages[actualStage]?.code === "personal" && (
            <AccountOwner
              formData={formData?.accountOwner}
              handleFormDataChange={handleFormDataChange}
              language={language}
            />
          )}
          {stages[actualStage]?.code === "company" && (
            <Company
              formData={formData?.company}
              handleFormDataChange={handleFormDataChange}
              language={language}
              getTaxData={getTaxData}
            />
          )}
          {stages[actualStage]?.code === "location" && (
            <Location
              formData={formData?.location}
              handleFormDataChange={handleFormDataChange}
              language={language}
              getZipData={getZipData}
            />
          )}
          {stages[actualStage]?.code === "sum" && (
            <Summary formData={formData} language={language} />
          )}
          {stages[actualStage]?.code === "done" && <Done language={language} />}
        </>
      )}
      <div className="error-container">
        {errors != null && errors.length > 0 && (
          <>
            <h2>{getT("initBrand.invalid.fields")}</h2>
            {errors.map((error, index) => (
              <p key={index}>{getT(error.errorMsgCode)}</p>
            ))}
          </>
        )}
      </div>
    </div>
  )
}
export default Stage
