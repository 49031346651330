import { brandInit } from "apis/brand/init"
import { useParams } from "react-router-dom"
import CreateBrandWizard from "components/CreateBrandWizard"
import useMultilang from "intl/useMultilang"

function CreateBrand() {
  const { code } = useParams()
  const { getT } = useMultilang()

  const submitNewBrandData = (data) => {
    return brandInit.sendData({
      ...data,
      resellerCode: code.length === 8 ? code : null,
      inviteUUID: code !== 8 ? code : null,
    })
  }

  if (code == null)
    return (
      <div className="external-brand-creator-root">
        <div className="wizard-conainer hidden appear">
          {getT("error.invalidLink")}
        </div>
      </div>
    )

  return (
    <div className="external-brand-creator-root">
      <div className="wizard-conainer hidden appear">
        <CreateBrandWizard submitData={submitNewBrandData} language={"hu"} />
      </div>
    </div>
  )
}
export default CreateBrand
