import dictionary from './dictionary'

function Actions(props) {

    const { goToNextStage, goToPrevStage, actualStage, stages, language, submitData, errors, resetErrors } = props

    return(
        <div className="actions-container">
            {errors == null && <>
                {actualStage !== 0 && actualStage !== stages.length-1 && <div className="back-btn" onClick={goToPrevStage}>{dictionary.prev?.[language]}</div>}
                {actualStage !== stages.length-1 && stages[actualStage]?.code !== "sum" && <div className="btn" onClick={goToNextStage}>{dictionary.next?.[language]}</div>}
                {stages[actualStage]?.code === "sum" && <div className="btn" onClick={submitData}>{dictionary.send?.[language]}</div>}
            </>}
            {errors != null && <div className="btn" onClick={resetErrors}>{dictionary.back?.[language]}</div>}
        </div>
    )
}
export default Actions