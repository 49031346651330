import axios from 'axios'
import {config} from '../../config.js'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage['iPanel-apiToken']
  }
})

export const globalHelp = {
  getAll: async () => {
    try {
      const axiosconfig = {
        params: {
          
        }
      }
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/help`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getByLangKey: async (lang, key) => {
    try {
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/help/${lang}/${key}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  getById: async (id) => {
    try {
      const response = await axiosGlobal.get(`${config().apiBaseUrl}api/v1/help/${id}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  setBlank: async (key) => {
    let body = {
      key
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/help/key`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  setHelp: async (topic) => {
    let body = {
      topic
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/help`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  linkTopic: async (topicID, linkedTopicID) => {
    let body = {
      topicID,
      linkedTopicID
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/help/link`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  linkBulkTopic: async (links) => {
    let body = {
      links
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/help/link/bulk`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  unlinkTopic: async (topicID, linkedTopicID) => {
    let body = {
      topicID,
      linkedTopicID
    }
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/help/unlink`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
}
