import { useState, useEffect } from "react"
import ButtonSimple from "components/Buttons/ButtonSimple"
import TextField from "components/TextField"
import { globalAuth } from 'apis/globalAuth'
import { toast } from 'react-toastify'
import useMultilang from 'intl/useMultilang'

function Register(props) {

    const { setScreenContent } = props

    const [userData, setUserData] = useState({email: localStorage['username'] != null ? localStorage['username'] : "", firstName: "", lastName: ""})
    const { getT } = useMultilang()

    function enterFunction(e) {
        if (e.key === "Enter") {
          handleClickOnNext()
        }
    }
    
    useEffect(() => {
    
        document.addEventListener("keydown", enterFunction, false)
    
        return () => { document.removeEventListener("keydown", enterFunction) }
        // eslint-disable-next-line
    }, [])
    
    const handleClickOnNext = () => {
      globalAuth.registerUser(userData).then(res => {
        if(res?.success) {
          toast.success(getT('global.login.success'))
          setScreenContent("checkEmail")
        } else {
          toast.error(getT('global.login.error'), { autoClose: 2000, hideProgressBar: false })
        }
      }).catch((e) => {
        console.error(e);
      })
    }

    const handleChange = e => setUserData({...userData, [e.target.name]: e.target.value})
            
    return(
        <div className="login-fields">
            <TextField
                label={getT('global.login.email')}
                name="email"
                value={userData.email}
                className="login-email"
                onChange={handleChange}
            />
            <TextField
                label={getT('global.login.lastName')}
                name="lastName"
                value={userData.lastName}
                className="login-email"
                onChange={handleChange}
            />
            <TextField
                label={getT('global.login.firstName')}
                name="firstName"
                value={userData.firstName}
                className="login-email"
                onChange={handleChange}
            />
            <ButtonSimple
                className="btn-brand-color btn-big mb-1" 
                text={getT('global.login.next')}          
                onClick={handleClickOnNext}                
            />
            <ButtonSimple
                className="btn-global-color btn-big" 
                text={getT('global.login.back')}          
                onClick={() => { setScreenContent("login") }}                
            />
        </div>
    )
}
export default Register