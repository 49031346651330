import React, { useEffect, useState, useRef } from "react"
import LoadSvg from "components/LoadSvg-v3"

function OkeOkeLoading(props) {
  const { loading, className } = props
  const timer = useRef(null)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    clearTimeout(timer.current)
    if (loading) {
      setVisible(true)
    } else {
      timer.current = setTimeout(() => {
        setVisible(false)
      }, 800)
    }
    return () => clearTimeout(timer.current)
  }, [loading])

  if (!visible) return null

  return (
    <div className={`okeoke-loading-screen ${loading ? "visible" : "fade-out"} ${className ? className : ""}`}>
      <div className="svg-container load-icon animated rotate">
        <LoadSvg name="okeOkeIcon" />
      </div>
    </div>
  )
}

export default OkeOkeLoading
