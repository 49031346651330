import axios from 'axios'
import {config} from 'config.js'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage['iPanel-apiToken']
  }
});

export const globalAuth = {
  generateUserPin: async (username) => {
    try {
      let body = {
        email: username
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/auth/generateUserPin`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return null
    }
  },
  generateUserToken: async (username, pinCode) => {
    try {
      let body = {
        email: username,
        pinCode: pinCode
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/auth/generateUserToken`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return null
    }
  },
  registerUser: async (userData) => {
    try {
      let body = {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/global/users/register`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return null
    }
  },
  validateUserToken: async () => {
    try {
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/auth/validateUserToken`, {});
      return response.data
    } catch (e) {
      console.error(e)
      return null
    }
  },
  generateBrandToken: async (brandID) => {
    try {
      let body = {
        brandID 
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/auth/generateBrandToken`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return null
    }
  },
  generateDevicePinCode: async (brandID, locationUUID, deviceUUID) => {
    try {
      let body = {
        brandID,
        locationUUID,
        deviceUUID,
        deviceType: 2
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/auth/generateDevicePinCode`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return null
    }
  },
  authorizeRemoteLogin: async (brandID, deviceUUID, code) => {
    try {
      let body = {
        brandID,
        deviceUUID,
        code
      }
      const response = await axiosGlobal.post(`${config().apiBaseUrl}api/v1/auth/device/authorizeRemoteLogin`, body);
      return response.data
    } catch (error) {
      console.log(error)
      return null
    }
  }
}
