import dictionary from '../dictionary'

function Done(props) {

    const { language } = props

    return(
        <div className="done-container">
            <h1>{dictionary.success?.[language]}</h1><br/>
            <p>{dictionary.checkMailbox?.[language]}</p>
        </div>
    )
}
export default Done